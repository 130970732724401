// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import '../../../visualizations.css'
import CategoryOptions from '../shared/CategoryOptions'
import sortCategoryData from '../../utils/sortCategoryData'
import VariableSelect from '../../../../../shared/VariableSelect'
import VariableSelectSecondary from '../../../../../shared/VariableSelectSecondary'

// the pie part of a form if a pie chart is chosen
function PieForm(props) {
  // initialization of the settings
  let settings = props.chartSpecificOptions

  // event handlers
  const handleChange = handleChangeFunc.bind(this)

  // EVENT HANDLERS:

  // Updates the settings,
  // then passes it to the general form.
  function handleChangeFunc(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    // if we change a variable, we also need to show new categories to be selected
    if (name === 'xAxis') {
      settings.categoriesSelectedX = props.uniqueCategories[value]
    }

    settings[name] = value
    props.onChange({
      target: {
        type: 'dict',
        value: settings,
        name: 'chartSpecificOptions',
      },
    })
  }

    let variableSelect
  const dataType = props.dataType

  if (dataType === "main") {
    variableSelect =
      <>
        <label className="med-vis-settings-panel-label">
          Variable <br />
          <VariableSelect
            className={'med-select'}
            defaultValue={settings.xAxis}
            name="xAxis"
            onChange={handleChange}
            tour="step-vis-vars"
          />
        </label>
      </>
  }
  else {
    variableSelect =
      <>
        <label className="med-vis-settings-panel-label">
          Variable <br />
          <VariableSelectSecondary
            className={'med-select'}
            defaultValue={settings.xAxis}
            name="xAxis"
            onChange={handleChange}
            tour="step-vis-vars"
          />
        </label>
      </> 
  }

  // RENDERER:

  return (
    <>
      {variableSelect}

      <div tour="step-vis-categories">     
        <CategoryOptions
          dimension="X"
          onChange={handleChange}
          categories={sortCategoryData(props.uniqueCategories[settings.xAxis])}
          categoriesSelected={settings.categoriesSelectedX}
          settings={settings}
        />
      </div>
    </>
  )
}

export default PieForm
