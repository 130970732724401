import config from '../../config/config'

const tourSteps = [
  {
    target: "[tour='step-nav-data']",
    content: "Use this link to access the medicines data page, on this page you can view, select and export all the datapoints available to you. Let's navigate to the data page and start selecting some datapoints!",
    title: "Medicines Data Page",
    placement: "auto",
    disableBeacon: true,
    page: "/"
  },
  {
    target: "[tour='step-data-search']",
    content: "On the data page, you find a search bar, use this search bar to look for specific medicines. We can search for 'pfizer' and all medicines related to pfizer will be displayed in the table below. Let's explore that table next!",
    title: "Data Search",
    placement: "auto",
    disableBeacon: true,
    page: config.routes.main.data
  },
  {
    target: "[tour='step-data-select']",
    content: "In this table you can select individual medicines using the checkmark on the left and view more information using the 'i' on the right. Use the filter and sort button to filter and sort the data points which are displayed in the table.",
    title: "Data Selection Table",
    placement: "auto",
    disableBeacon: true,
    page: config.routes.main.data
  },
  {
    target: "[tour='step-data-selected']",
    content: "All of the medicines that you have selected in the table above will appear here. You can either export these medicines to a file (using the export button), or use them to create a visualization. Let's see if we can make an informative visualization using these datapoints.",
    title: "Selected Medicines",
    placement: "auto",
    disableBeacon: true,
    page: config.routes.main.data
  },
  {
    target: "[tour='step-nav-vis']",
    content: "To create a visualization, we first need to navigate to the visualization page. We can use this link to accomplish this.",
    title: "Visualizations Page",
    placement: "auto",
    disableBeacon: true,
    page: config.routes.main.data
  },
  {
    target: "[tour='step-vis-main']",
    content: "On the visualization page, we are greeted by a single visualization. But to make it ours, we still need to adjust some of its parameters.",
    title: "Visualization",
    placement: "auto",
    disableBeacon: true,
    page: config.routes.main.visualizations
  },
  {
    target: "[tour='step-vis-type']",
    content: "First, we need to pick a chart type, for example a bar or pie chart. If applicable you can select extra modifiers below to further customize the chart, like flipping the chart on its side (using horizontal). Here we select the pie chart type, this way we can see how many occurences of different pfizer MAHs are present.",
    title: "Chart Type",
    placement: "auto",
    disableBeacon: true,
    page: config.routes.main.visualizations
  },
  {
    target: "[tour='step-vis-vars']",
    content: "Next we need to decide what variables we want to visualize. For bar and line charts we need to select two variables, but for a pie chart one is more than enough. For a histogram we only need to select one variable, since we want to plot against time.",
    title: "Variables",
    placement: "auto",
    disableBeacon: true,
    page: config.routes.main.visualizations
  },
  {
    target: "[tour='step-vis-categories']",
    content: "Finally, we can select certain categories, for both the X-axis and the Y-axis variables to be included or excluded from the visualization. For our example we are interested in 2 out of 3 categories.",
    title: "Categories",
    placement: "auto",
    disableBeacon: true,
    page: config.routes.main.visualizations
  },
  {
    target: "[tour='step-vis-plot']",
    content: "After having configured all of the appropriate settings, we are left with our final result. Here we can see a distribution of different pfizer MAHs. If we want to download this visualisation, we can do so using the two buttons below.",
    title: "Final Visualization",
    placement: "auto",
    disableBeacon: true,
    page: config.routes.main.visualizations
  },
  {
    target: "[tour='step-nav-home']",
    content: "That concludes our tour, you will be returned to the home page after which you can start to use all of the features that this dashboard has to offer!",
    title: "End of Tour",
    placement: "auto",
    disableBeacon: true,
    page: config.routes.main.visualizations
  }
];

export default tourSteps; 