// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'

// Function based component that renders a checkbox
function CheckboxColumn({ value, onChange, select }) {
  return (
    <td className="med-table-body-cell med-table-narrow-column med-column-left">
      {select ? (
        <input
          className="tableCheckboxColumn"
          type="checkbox"
          checked={value || false}
          onChange={onChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter') onChange()
          }}
        />
      ) : (
        <i
          className={`bx bx-trash med-table-icons med-primary-text ${value ? 'checked' : ''}`}
          onClick={onChange} // Click event handler for toggling the state
        />
      )}
    </td>
  );
}


export default CheckboxColumn
