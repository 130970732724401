// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useState } from 'react';

function AmountPerPage({ data, resultsPerPage }) {
  const [customValue, setCustomValue] = useState('');

  // Available options
  let options = [<option key={15} value={15}>15</option>];

  // Calculate the max amount of entries per page
  let upper = data.length >= 300 ? 300 : data.length;
  if (data.length % 25 > 0 && data.length < 300) upper += 25;

  // Populate the options
  for (let j = 25; j <= upper; j += 25) {
    options.push(
      <option key={j} value={j}>
        {j}
      </option>
    );
  }

  const handleCustomInputChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setCustomValue(value);
    }
  };

  const handleCustomInputBlur = () => {
    updateResultsPerPage();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      updateResultsPerPage();
    }
  };

  const updateResultsPerPage = () => {
    const value = parseInt(customValue, 10);
    if (value && value > 0 && value <= upper) {
      resultsPerPage(value);
    }
  };

  return (
    <div className="med-result-count-selector-container">
      <label>Amount per page</label>
      <select
        onChange={(event) => resultsPerPage(parseInt(event.target.value, 10))}
        name="AmountShown"
        className="med-select"
        id="med-result-count-selector"
      >
        {options}
      </select>
      <div className="custom-input-container">
        <input
          type="text"
          value={customValue}
          onChange={handleCustomInputChange}
          onBlur={handleCustomInputBlur}
          onKeyPress={handleKeyPress}
          placeholder="Custom"
          className="med-select"
          style={{ width: '70px' }}
        />
      </div>
    </div>
  );
}

export default AmountPerPage;