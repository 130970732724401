import React, { useState, useRef, useEffect } from 'react';
import { useData } from '../contexts/DataContext'

const excludedKeys = [
  'eu_brand_name_initial',
  'eu_brand_name_current',
  'eu_indication_current',
  'eu_indication_initial',
  'ema_url',
  'ec_url',
  'aut_url',
  'smpc_url',
  'epar_url',
  'sci_disc_url'
];

const generateHints = (medicine) => {
  if (!medicine) return [];
  const availableKeys = Object.keys(medicine).filter(key =>
    !excludedKeys.includes(key) && medicine[key] && medicine[key] !== 'NA' && medicine[key] !== 'False'
  );

  const shuffledKeys = [...availableKeys].sort(() => Math.random() - 0.5);
  return shuffledKeys.slice(0, 5).map(key => {
    const formattedKey = key.replace(/_/g, ' ').toUpperCase();
    return `${formattedKey}: ${medicine[key]}`;
  });
};

const MedicineGuessGame = () => {
  try {
    // Move data hook and initialization to the top level
    const data = useData();
    const [allMedicines, setAllMedicines] = useState([]);
    const [allBrandNames, setAllBrandNames] = useState([]);
    const [isAtmpMode, setIsAtmpMode] = useState(false);

    // Component state
    const [currentMedicine, setCurrentMedicine] = useState(null);
    const [userGuess, setUserGuess] = useState('');
    const [message, setMessage] = useState('');
    const [revealedHints, setRevealedHints] = useState([]);
    const [guessCount, setGuessCount] = useState(0);
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [hints, setHints] = useState([]);
    const [showAnswer, setShowAnswer] = useState(false);

    // Refs
    const suggestionsRef = useRef(null);
    const inputRef = useRef(null);


    const toggleShowAnswer = () => {
      setShowAnswer(prev => !prev);
    };

    // Initialize data
    useEffect(() => {
      if (data) {
        const filteredMedicines = isAtmpMode
          ? data.filter(med => med.eu_atmp === 'True')
          : data;

        setAllMedicines(filteredMedicines);
        const brandNames = [...new Set(filteredMedicines.map(med => med.eu_brand_name_current))]
          .filter(name => name && name !== 'NA');
        setAllBrandNames(brandNames);
        const randomMedicine = filteredMedicines[Math.floor(Math.random() * filteredMedicines.length)];
        setCurrentMedicine(randomMedicine);
        setHints(generateHints(randomMedicine));
      }
    }, [data, isAtmpMode]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (suggestionsRef.current && !suggestionsRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
          setShowSuggestions(false);
          setSelectedIndex(-1);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    if (!allMedicines || allMedicines.length === 0) {
      return <div>No medicine data available. Please try again later.</div>;
    }

    const handleInputChange = (e) => {
      const value = e.target.value;
      setUserGuess(value);
      setSelectedIndex(-1);
  
      if (value.length > 0) {
          const lowerCaseValue = value.toLowerCase();
  
          const matchingStart = allBrandNames.filter(name => 
              name.toLowerCase().startsWith(lowerCaseValue)
          );
  
          const matchingSubstring = allBrandNames.filter(name =>
              !name.toLowerCase().startsWith(lowerCaseValue) && // Exclude start matches
              name.toLowerCase().includes(lowerCaseValue)
          );
  
          const combinedSuggestions = matchingStart.concat(matchingSubstring).slice(0, 5);
  
          setSuggestions(combinedSuggestions);
          setShowSuggestions(true);
      } else {
          setSuggestions([]);
          setShowSuggestions(false);
      }
  };

    const toggleAtmpMode = () => {
      setIsAtmpMode(prev => !prev);
    };

    const handleKeyDown = (e) => {
      if (!showSuggestions) {
        if (e.key === 'Enter') {
          checkGuess();
        }
        return;
      }

      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex(prev =>
            prev < suggestions.length - 1 ? prev + 1 : prev
          );
          break;
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex(prev => prev > -1 ? prev - 1 : prev);
          break;
        case 'Enter':
          e.preventDefault();
          if (selectedIndex >= 0) {
            handleSuggestionClick(suggestions[selectedIndex]);
          } else {
            checkGuess();
          }
          break;
        case 'Escape':
          setShowSuggestions(false);
          setSelectedIndex(-1);
          break;
        default:
          break;
      }
    };

    const handleSuggestionClick = (suggestion) => {
      setUserGuess(suggestion);
      setShowSuggestions(false);
      setSelectedIndex(-1);
      inputRef.current.focus();
    };

    const checkGuess = () => {
      const newGuessCount = guessCount + 1;
      setGuessCount(newGuessCount);

      if (userGuess.toLowerCase() === currentMedicine.eu_brand_name_current.toLowerCase()) {
        setMessage(`Correct! 🎉 You got it in ${newGuessCount} ${newGuessCount === 1 ? 'guess' : 'guesses'}!`);
      } else {
        setMessage(`Incorrect, try again! (Attempts: ${newGuessCount})`);
      }
    };

    const revealHint = () => {
      if (revealedHints.length < hints.length) {
        setRevealedHints([...revealedHints, hints[revealedHints.length]]);
      }
    };

    const maskBrandName = (text, brand) => {
      const regex = new RegExp(`(${brand})`, 'gi');
      return text.replace(regex, 'XXXX');
    };

    const getNewMedicine = () => {
      const newMedicine = allMedicines[Math.floor(Math.random() * allMedicines.length)];
      setCurrentMedicine(newMedicine);
      setHints(generateHints(newMedicine));
      setUserGuess('');
      setMessage('');
      setRevealedHints([]);
      setGuessCount(0);
    };

    return (
      <div>
        <h1> Guess the Medicine!</h1>
        <p>
          You are given a random indication with the product crossed out (XXXX). Can you guess the product for which the indication is shown?
        </p>
        <hr className="med-top-separator" />
        <div>
          <h3>Mystery Indication:</h3>
          <p>{maskBrandName(currentMedicine.eu_indication_current, currentMedicine.eu_brand_name_current)}</p>
        </div>

        <div>
          <p>
            <input
              ref={inputRef}
              type="text"
              className="med-text-input"
              placeholder="Enter brand name"
              value={userGuess}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              aria-expanded={showSuggestions}
              aria-autocomplete="list"
              aria-controls="suggestions-list"
              role="combobox"
            />
            {showSuggestions && suggestions.length > 0 && (
              <div
              ref={suggestionsRef}
              id="suggestions-list"
              role="listbox"
              className="absolute z-10 w-full mt-1 border rounded shadow-lg med-text-input"
          >
              {suggestions.map((suggestion, index) => (
                  <div
                      key={index}
                      role="option"
                      aria-selected={index === selectedIndex}
                      onClick={() => handleSuggestionClick(suggestion)}
                      onMouseEnter={() => setSelectedIndex(index)}
                  >
                      {suggestion}
                  </div>
              ))}
          </div>

            )}
            {showAnswer && (
              <p className="mt-2 font-bold text-green-600">
                Correct Answer: {currentMedicine.eu_brand_name_current}
              </p>
            )}
          </p>
          <button
            onClick={checkGuess}
            className="med-primary-solid med-bx-button"
          >
            Submit
          </button>
          <button
            onClick={revealHint}
            disabled={revealedHints.length >= hints.length}
            className="med-primary-solid med-bx-button"
          >
            Get Random Hint
          </button>
          <button
  onClick={() => {
    if (showAnswer) {
      getNewMedicine();
      setShowAnswer(false); // Reset answer visibility for the new medicine
    } else {
      setShowAnswer(true);
    }
  }}
  className="med-primary-solid med-bx-button"
>
  {showAnswer ? 'New Medicine' : 'Show Answer'}
</button>
          <button
            onClick={toggleAtmpMode}
            className={`med-primary-solid med-bx-button`}
          >
            {isAtmpMode ? 'ATMP Mode is On 🔥' : 'ATMP Mode is Off'}
          </button>
        </div>

        {message && <p className="mb-4 font-bold">{message}</p>}

        {revealedHints.length > 0 && (
          <div>
            <p className="font-bold mb-2">Hints:</p>
            <ul className="list-disc pl-6">
              {revealedHints.map((hint, index) => (
                <li key={index} className="mb-1">{hint}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  } catch (error) {
    console.error("An error occurred in MedicineGuessGame:", error);
    return <div>Game not available.</div>;
  }
};


export { MedicineGuessGame };