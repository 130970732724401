const config = {
  // Application name shown in browser tab
  appName: "EMRD",

  // Feature flags
  features: {
    secondaryData: true, // Toggle secondary data pages and functionality
  },

  // Header configuration
  header: {
    title: "European Medicines Regulatory Database",
    githubIssueLink: "https://github.com/cbg-meb/medicines-regulatory-db/issues",
    githubTooltip: "Incorrect data or bug in website? \nClick here to raise a Github issue"
  },

  // Navigation menu text configuration
  navigation: {
    // Top level navigation items
    home: "Home",
    info: "Info", 
    about: "About",
    
    // Primary data section navigation items
    main: {
      data: "Medicines Data",
      visualizations: "Medicines Visualizations"
    },
    
    // Secondary data section navigation items
    secondary: {
      data: "Orphan Designations",
      visualizations: "Orphan Designations Visualizations"
    },
    
    // Account related navigation items
    account: "Account",
    login: "Login", 
    logout: "Logout"
  },

  // Data labels and placeholders configuration
  data: {
    // Primary data section labels
    name: "Medicines",
    singular: "medicine",
    plural: "medicines",
    search_placeholder: "Explore by Name, Code, Brand, Condition, etc. (COVID-19, EU/1/20, L01xx, impairment)",
    primary_key: "eu_pnumber",
    
    // Secondary data section labels
    secondary_name: "Orphan Designations",
    secondary_singular: "orphan designation",
    secondary_plural: "orphan designations",
    secondary_search_placeholder: "Explore by Name, Code, Condition, etc. (COVID-19, EU/3/20, rare disease)",
    secondary_primary_key: "eu_od_number"
  },

  // URL routes configuration
  routes: {
    // Primary data section routes
    main: {
      data: "/medicines",
      visualizations: "/medicines_visualizations", 
      details: "/medicine_page"
    },
    // Secondary data section routes
    secondary: {
      data: "/orphan_designations",
      visualizations: "/orphan_visualizations",
      details: "/orphan_page"
    }
  },

  // API endpoint configuration
  api: {
    endpoints: {
      // Authentication endpoints
      login: '/api/account/login/',
      logout: '/api/account/logout/',
      
      // Primary data endpoints
      main_data: '/api/medicine/human/',
      main_structureData: '/api/structureData/human/',
      update_date: '/api/medicine/scrape-date/',
      
      // Secondary data endpoints
      secondary_data: '/api/medicine/orphan/',
      secondary_structureData: '/api/structureData/orphan/'
    }
  },

  // Footer configuration
  footer: {
    // Organization links shown in footer
    organization_title: "Relevant Organizations",
    organizations: [
      {
        name: "Medicines Evaluation Board",
        url: "https://english.cbg-meb.nl/"
      },
      {
        name: "Utrecht University",
        url: "https://www.uu.nl/en"
      }
    ],
    copyright: "Utrecht University"
  },

  // Visualization configuration
  visualizations: {
    // Primary data visualization defaults
    main: {
      xAxis: "eu_atmp",
      yAxis: "eu_nas"
    },
    // Secondary data visualization defaults
    secondary: {
      xAxis: "eu_od_number",
      yAxis: "eu_od_con"
    }
  },

  // Column configuration
  columns: {
    // Primary data default columns
    main: {
      default: [
        'eu_pnumber',
        'active_substance_current',
        'eu_brand_name_current',
        'eu_mah_current',
        'eu_aut_date'
      ]
    },
    // Secondary data default columns
    secondary: {
      default: [
        'eu_od_number',
        'ema_od_number',
        'eu_od_con',
        'eu_od_date',
        'eu_od_comp_date',
        'eu_od_sponsor'
      ]
    }
  }
};

export default config; 