// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import Toggle from './components/Toggle'
import NavLink from './components/NavLink'
import NavAccount from './components/NavAccount'
import LoginModal from '../login/LoginModal'
import OutsideClickHandler from 'react-outside-click-handler'
import './Navigation.css'
import handleLogOut from '../handlers/logout'
import config from '../../config/config'
import { useScrollToTop } from '../hooks/useScrollToTop'

// Class based component rendering the entire navigation side bar
// Because of the extensive state, we use a class instead of a function based component here
class SideNavigation extends React.Component {
  // Initialize the navigation bar in the collapsed position
  constructor(props) {
    super(props)

    // Get login status from session storage
    let username = sessionStorage.getItem('username')
    let accessLevel = sessionStorage.getItem('access_level')
    let token = sessionStorage.getItem('token')
    let loggedIn = token !== null

    // Set the default state
    this.state = {
      expanded: false,
      loggedIn: loggedIn,
      isAdmin: false,
      userName: username,
      accessLevel: accessLevel,
    }
  }

  // Calls logout handler and updates local state
  async logOut() {
    await handleLogOut()
    this.setState({
      loggedIn: false,
      isAdmin: false,
      userName: '',
      accessLevel: '',
    })
  }

  // Toggles the navigation bar, if the bar is in the expanded position,
  // the navigation bar is collapsed and vice versa
  toggle() {
    this.setState({ expanded: !this.state.expanded })
  }

  // Collapses the navigation bar
  close() {
    this.setState({ expanded: false })
  }

  // Returns a string representation of the navigation bar state, the navigation
  // bar is either in the open (expanded) or closed (collapsed) state
  getState() {
    return this.state.expanded ? 'med-open' : 'med-closed'
  }

  // Renders the navigation bar
  render() {
    return (
      <ScrollToTop>
        <nav className={'med-side-nav ' + this.getState()}>
          <Toggle expanded={this.state.expanded} parent={this} />
          <NavLink
            tour="step-nav-home"
            name={config.navigation.home}
            image="bx bx-home-alt-2"
            dest="/"
            parent={this}
          />
          <NavLink
            name={config.navigation.info}
            image="bx bx-info-circle"
            dest="/info"
            parent={this}
          />
          <NavLink
            name={config.navigation.about}
            image="bx bx-book-open"
            dest="/about"
            parent={this}
          />
          <NavLink
            tour="step-nav-data"
            name={config.navigation.main.data}
            image="bx bx-data"
            dest={config.routes.main.data}
            parent={this}
          />
          <NavLink
            tour="step-nav-vis"
            name={config.navigation.main.visualizations}
            image="bx bx-bar-chart"
            dest={config.routes.main.visualizations}
            parent={this}
          />
          {config.features.docShark && (
            <NavLink
              tour="step-nav-search"
              name={"Document Shark"}
              image="bx bx-search-alt"
              dest={config.routes.main.docShark}
              parent={this}
            />
          )}
          {config.features.secondaryData && (
            <>
              <NavLink
                tour="step-nav-data"
                name={config.navigation.secondary.data}
                image="bx bx-data"
                dest={config.routes.secondary.data}
                parent={this}
              />
              <NavLink
                tour="step-nav-vis"
                name={config.navigation.secondary.visualizations}
                image="bx bx-bar-chart"
                dest={config.routes.secondary.visualizations}
                parent={this}
              />
            </>
          )}
          {/* {this.state.loggedIn && (
            <NavAccount
              user={{
                userName: this.state.userName,
                accessLevel: this.state.accessLevel,
              }}
              dest="/account"
              parent={this}
            />
          )} */}
          {this.state.loggedIn ? (
            <NavLink
              name={config.navigation.logout}
              image="bx bx-log-out"
              dest="/"
              parent={this}
              lowest={true}
              onClick={this.logOut.bind(this)}
            />
          ) : (
            <LoginModal parent={this} />
          )}
        </nav>
      </ScrollToTop>
    )
  }
}

// Wrapper component to use hooks with class component
function ScrollToTop({ children }) {
  useScrollToTop()
  return children
}

export default SideNavigation
