// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDataSecondary } from '../../shared/contexts/Secondary/DataContextSecondary'
import { useStructureSecondary } from '../../shared/contexts/Secondary/StructureContextSecondary'
import { useTableUtilsSecondary } from '../../shared/contexts/Secondary/TableUtilsContextSecondary'
import { useNavigate } from 'react-router-dom'
import Search from '../../shared/search/Search'
import config from '../../config/config'
import { StringToData } from '../components/GeneralFunctions'
import { v4 } from 'uuid'

import './DetailedInfo.css'
import DetailGroup from './components/DetailGroup'
import Detail from './components/Detail'
import Procedure from './components/Procedure'
import CustomLink from './components/CustomLink'
import TimeLine from './components/TimeLine'
import ProcSelectModal from './components/ProcSelectModal'
import DownloadChangelog from '../components/DownloadChangelog'
import CollapsibleButton from '../components/CollapsibleButton'

// Function based component, which represents the top level detailed info page
// component, it collects and fetches all the correct data and then passes this data
// to the info page component and returns that component
export default function DetailedInfoPageSecondary() {
  const { medID } = useParams()
  const [procData, setProcData] = useState(null)
  const navigate = useNavigate()
  const { tableUtils, setTableUtils } = useTableUtilsSecondary()
  const allData = useDataSecondary()

  const search = (query) => {
    setTableUtils({ ...tableUtils, search: query })
    navigate(config.routes.secondary.data)
  }

  let medData = allData.find(
    (element) => element[config.data.secondary_primary_key].toString() === StringToData(medID).toString(),
  )

  return (
    <div>
      <Search update={search} dataType="secondary" />
      <InfoPage
        medData={medData}
        lastUpdatedDate={undefined}
      />
    </div>
  )
}

// Function based component, which represents the entire detailed information page
// it display the given medicine and procedure data
export function InfoPage({ medData, lastUpdatedDate, procData }) {
  const variableCategories = useStructureSecondary()

  // A filter which determines which prodcures to show, and which to omit from
  // the detailed info page
  // const [procFilter, setProcFilter] = useState([
  //   'Centralised - Authorisation',
  //   'Centralised - Transfer Marketing Authorisation Holder',
  //   'Centralised - Annual reassessment',
  //   'Centralised - Annual renewal',
  // ])

  // If no medicine data is provided, no meaning full can be displayed
  if (!medData) {
    return (
      <h1 className="med-info-unknown-medID">Unknown Medicine ID Number</h1>
    )
  }

  // If there is procedure data present, two containers should be added to the page
  // with only the relevant procedure entries (based on the display proc types above)
  // one container should display the procedures in text form, and the other should
  // visually display them as a timeline. If no procedure data is present, both
  // containers should not be displayed.
  // let procedureContrainer = null
  // let timeLineContainer = null

  // if (procData !== null) {
  //   const removeDuplicates = (arr) => {
  //     return arr.filter((item, index) => arr.indexOf(item) === index)
  //   }
  //   const availableProcTypes = removeDuplicates(
  //     procData.map((proc) => proc.proceduretype)
  //   )
  //   const procSelectModal = (
  //     <ProcSelectModal
  //       availableProcTypes={availableProcTypes}
  //       currentProcFilter={procFilter}
  //       setProcFilter={setProcFilter}
  //     />
  //   )

  //   const procedures = procData.filter((proc) =>
  //     procFilter.includes(proc.proceduretype)
  //   )

  //   const noProcs = procedures.length === 0
  //   const noProcMessage = (
  //     <p className="med-info-no-proc-message">
  //       There are no procedures to display, make sure that there are procedure
  //       types selected for display in the menu in the top right corner of the
  //       container.
  //     </p>
  //   )

  //   procedureContrainer = (
  //     <div className="med-content-container med-info-container">
  //       <h1>Procedure Details</h1>
  //       {procSelectModal}
  //       <hr className="med-top-separator" />
  //       {!noProcs
  //         ? procedures.map((proc) => {
  //             return <Procedure proc={proc} key={v4()} />
  //           })
  //         : noProcMessage}
  //     </div>
  //   )

  //   timeLineContainer = (
  //     <div className="med-content-container med-info-container">
  //       <h1>Medicine Timeline</h1>
  //       {procSelectModal}
  //       <hr className="med-top-separator" />
  //       {!noProcs ? (
  //         <TimeLine procedures={procedures} lastUpdatedDate={lastUpdatedDate} />
  //       ) : (
  //         noProcMessage
  //       )}
  //     </div>
  //   )
  // }

  const detailGroups = []

  for (let category in variableCategories) {
    const details = []

    for (let varIndex in variableCategories[category]) {
      const variable = variableCategories[category][varIndex]
      if (variable['data-format'] !== 'link') {
        let value = medData[variable['data-key']]
        if (variable['data-format'] === 'date') {
          //value = slashDateToStringDate(value)
        }

        value = value.toString()

        details.push(
          <Detail
            name={variable['data-value']}
            value={value} key={v4()}
          />
        )
      }
    }

    if (details.length > 0) {
      detailGroups.push(
        <DetailGroup title={category} key={v4()}>
          {details}
        </DetailGroup>
      )
    }
  }

  // Returns the component which discribes the entire detailed information page
  // the page consists of three containers, each holds a specific category of
  // information pertaining to the current medicine. This first holds general
  // information, the next procedure related information, and the last holds some
  // links to external website which could be useful.
  return (
    <div>
      <div className="med-content-container">
        <h1>{medData[config.data.secondary_primary_key]} - Medicine Details</h1>
        <hr className="med-top-separator" />

        <div className="med-flex-columns">{detailGroups}</div>
      </div>

      {/* <div className="med-content-container">
        <h1 className="med-header">Changelog</h1>

        <DownloadChangelog
          data={Record}
          fileName={"file"}
          fileType=".json"
        />

        <DownloadChangelog
          data={Record}
          fileName={"file"}
          fileType=".txt"
        />


        <hr className="med-top-separator" />
        
        <CreateChangelog
          json={Record}
        />

        <hr className="med-top-separator" />
        <div className='med-info-changelog'>

        </div>
      </div> */}

      {/* {timeLineContainer}
      {procedureContrainer} */}

      {<div className="med-content-container">
        <h1>Additional Resources</h1>
        <hr className="med-top-separator" />

        {(() => {
          const links = [
            {
              url: medData.ema_url,
              name: `EMA Website for ${medData[config.data.secondary_primary_key]}`
            },
            {
              url: medData.ec_url,
              name: `EC Website for ${medData[config.data.secondary_primary_key]}`
            }
          ];

          const validLinks = links.filter(link => isValidURL(link.url));

          if (validLinks.length === 0) {
            return (
              <p style={{ paddingLeft: '15px' }}>
                No external resources are currently available.
              </p>
            );
          }

          return validLinks.map(link => (
            <CustomLink
              key={link.url}
              className="med-info-external-link"
              name={link.name}
              dest={link.url}
            />
          ));
        })()}
      </div>}
    </div>
  )

  function isValidURL(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }
}
