import React, { createContext, useContext } from 'react';
import countryFlagEmoji from 'country-flag-emoji';

const CountryEmojiContext = createContext();

export const COUNTRY_EMOJI_MAP = {
  'Austria': countryFlagEmoji.get('AT').emoji,
  'Belgium': countryFlagEmoji.get('BE').emoji,
  'Croatia': countryFlagEmoji.get('HR').emoji,
  'Czech Republic': countryFlagEmoji.get('CZ').emoji,
  'Denmark': countryFlagEmoji.get('DK').emoji,
  'Estonia': countryFlagEmoji.get('EE').emoji,
  'Finland': countryFlagEmoji.get('FI').emoji,
  'France': countryFlagEmoji.get('FR').emoji,
  'Germany': countryFlagEmoji.get('DE').emoji,
  'Greece': countryFlagEmoji.get('GR').emoji,
  'Hungary': countryFlagEmoji.get('HU').emoji,
  'Iceland': countryFlagEmoji.get('IS').emoji,
  'Ireland': countryFlagEmoji.get('IE').emoji,
  'Italy': countryFlagEmoji.get('IT').emoji,
  'Latvia': countryFlagEmoji.get('LV').emoji,
  'Lithuania': countryFlagEmoji.get('LT').emoji,
  'Malta': countryFlagEmoji.get('MT').emoji,
  'Netherlands': countryFlagEmoji.get('NL').emoji,
  'Norway': countryFlagEmoji.get('NO').emoji,
  'Poland': countryFlagEmoji.get('PL').emoji,
  'Portugal': countryFlagEmoji.get('PT').emoji,
  'Romania': countryFlagEmoji.get('RO').emoji,
  'Slovakia': countryFlagEmoji.get('SK').emoji,
  'Slovenia': countryFlagEmoji.get('SI').emoji,
  'Spain': countryFlagEmoji.get('ES').emoji,
  'Sweden': countryFlagEmoji.get('SE').emoji,
  'United Kingdom': countryFlagEmoji.get('GB').emoji,
  'No source available': '❓',
  'Unable to retrieve': '❌'
};

export function getCountryWithEmoji(countryName) {
  const emoji = COUNTRY_EMOJI_MAP[countryName] || '';
  return emoji ? `${countryName} ${emoji}` : countryName;
}

export function CountryEmojiProvider({ children }) {
  return (
    <CountryEmojiContext.Provider value={{ COUNTRY_EMOJI_MAP, getCountryWithEmoji }}>
      {children}
    </CountryEmojiContext.Provider>
  );
}

export function useCountryEmoji() {
  const context = useContext(CountryEmojiContext);
  if (context === undefined) {
    throw new Error('useCountryEmoji must be used within a CountryEmojiProvider');
  }
  return context;
} 