// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import DataSelectSecondary from '../data/data_select/DataSelectSecondary'
import SelectedData from '../data/selected_data/SelectedData'
import { useSelectedDataSecondary } from '../../shared/contexts/Secondary/SelectedContextSecondary'

// Function based component that displays all the components on the data page
function SecondaryPage() {
  const selectedData = useSelectedDataSecondary()
  return (
    <>
      <DataSelectSecondary/>
      <SelectedData selectedData={selectedData} dataType={"secondary"}/>
    </>   
  )
}

export default SecondaryPage
