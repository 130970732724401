// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import DataSelect from './data_select/DataSelect'
import SelectedData from './selected_data/SelectedData'
import Search from '../../shared/search/Search'
import './DataPage.css'
import { useData } from '../../shared/contexts/DataContext'
import { useTableUtils } from '../../shared/contexts/TableUtilsContext'
import { useSelectedData } from '../../shared/contexts/SelectedContext'

// Function based component that displays all the components on the data page
function DataPage() {
    const { tableUtils, setTableUtils } = useTableUtils()
    const selectedData = useSelectedData()
    const allData = useData()
    const totalRows = allData.length
  return (
    <>
      <Search
        tour="step-data-search"
        update={(e) => setTableUtils({ ...tableUtils, search: e })}
        initial={tableUtils.search}
        searchText={true}
        totalRows={totalRows}
      />
      <SelectedData selectedData={selectedData} dataType={"main"}/>
      <DataSelect/>
    </>
  )
}

export default DataPage
