// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import Chart from 'react-apexcharts'
import * as Colors from './Colors'
import { useStructureMapping } from '../../../../shared/contexts/StructureContext'

// histogram chart component
function HistogramChart(props) {
  const dataKeyToValueMap = useStructureMapping()

  let settings = {
    options: {
      chart: {
        id: String(props.id),
        type: 'bar',
        toolbar: { show: false },
        fontFamily: 'Poppins, sans-serif',
        foreColor: 'var(--text-primary)',
        // events: {
        //   dataPointSelection: (event, chartContext, config) => {
        //     let variables =
        //       props.series[config.seriesIndex].variables[config.dataPointIndex]
        //     props.onDataClick(variables)
        //   },
        // },
      },
      colors: Colors.histogram_color,
      title: {
        text: props.name ? props.title : '',
        align: 'center',
        style: {
          fontWeight: '500',
          fontSize: '20px', // Increased font size for the title
          color: 'var(--text-primary)',
        },
      },
      xaxis: {
        categories: props.categories,
        labels: {
          rotateAlways: true,
          trim: true,
        },
        tickPlacement: 'on',
        title: {
          text: dataKeyToValueMap[props.options.xAxis],
          style: {
            fontWeight: '300',
            fontSize: 'small',
            color: 'var(--text-primary)',
          },
        },
      },
      yaxis: {
        title: {
          text: 'Count',
          style: {
            fontWeight: '300',
            fontSize: 'small',
            color: 'var(--text-primary)',
          },
        },
      },
      dataLabels: {
        enabled: props.labels,
      },
      legend: {
        show: props.legend,
      },
      noData: {
        text: `You can select the categories to be displayed.`,
      },
      states: {
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
    },
    series: props.series,
  }

  // RENDERER:

  // renders a histogram chart
  return (
    <Chart
      options={settings.options}
      series={settings.series}
      type="bar"
      height={700}
    />
  )
}

export default HistogramChart
