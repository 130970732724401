import sortCategoryData from '../utils/sortCategoryData'
import pollChosenVariable from './shared_one_dimension/pollChosenVariable'
import createSelectedSeries from './shared_two_dimensions/createSelectedSeries'
import toSeriesFormat from './shared_two_dimensions/toSeriesFormat'


export default function generateBoxplotSeries(settings) {
  // Return empty array if no categories are selected
  if (
    settings.chartSpecificOptions.categoriesSelectedX.length === 0 ||
    settings.chartSpecificOptions.categoriesSelectedY.length === 0
  ) {
    return []
  }

  const xAxis = settings.chartSpecificOptions.xAxis;
  const yAxis = settings.chartSpecificOptions.yAxis;
  const data = settings.data;

  const categoriesSelectedX = sortCategoryData(settings.chartSpecificOptions.categoriesSelectedX);
  const categoriesSelectedY = sortCategoryData(settings.chartSpecificOptions.categoriesSelectedY);

  // Prepare the series data
  const seriesData = [];

  categoriesSelectedY.forEach((category) => {
    const yValues = [];

    // Collect y values for the current category
    data.forEach((item) => {
      if (item[yAxis] === category) {
        const value = parseFloat(item[xAxis]); // Ensure to get a numerical value
        if (!isNaN(value)) {
          yValues.push(value);
        }
      }
    });

    // Calculate boxplot statistics
    if (yValues.length > 0) {
      const boxplotValues = calculateBoxplot(yValues);
      seriesData.push({
        x: category,
        y: boxplotValues,
      });
    }
  });

  return {
    series: [
        {
            data: seriesData
        }
    ]
};
}

/**
 * Calculates boxplot statistics for a given array of values.
 * @param {Array} values - The array of numerical values.
 * @returns {Array} - The boxplot statistics [min, Q1, median, Q3, max].
 */
function calculateBoxplot(values) {
  values.sort((a, b) => a - b); // Sort the values

  const min = values[0];
  const max = values[values.length - 1];
  const median = calculateMedian(values);
  const Q1 = calculateMedian(values.slice(0, Math.floor(values.length / 2)));
  const Q3 = calculateMedian(values.slice(Math.ceil(values.length / 2)));

  return [min, Q1, median, Q3, max];
}

/**
 * Calculates the median of an array of values.
 * @param {Array} values - The array of numerical values.
 * @returns {number} - The median value.
 */
function calculateMedian(values) {
  const mid = Math.floor(values.length / 2);
  if (values.length % 2 === 0) {
    return (values[mid - 1] + values[mid]) / 2; // Even length
  } else {
    return values[mid]; // Odd length
  }
}