// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useContext, useState, useEffect } from 'react'
import { fetchWithToken } from '../api'
import config from '../../config/config'

// Create a new React context for the structure data
// The structure is used to determine the variables that can be selected
// And how the data must be formatted
const StructureContext = React.createContext()
const columnsToExclude = config.exclude.entire_site || []

// Function that returns the context such that the data can be used in other components
export function useStructure() {
  return useContext(StructureContext)
}

export function useStructureExclude() {
  return filterStructResponse(useStructure(), columnsToExclude)
}

export function useStructureMapping() {
  const mapKeysToValues = (categories) => {
    return Object.values(categories).flat().reduce((acc, item) => {
      acc[item['data-key']] = item['data-value'];
      return acc;
    }, {});
  };
  
  const variableCategories = useStructure()
  return mapKeysToValues(variableCategories);
}

// removes columns in config.exclude.entire_site from the structure data
function filterStructResponse(data, excludeKeys) {
  const filteredData = {};
  for (const category in data) {
    if (data.hasOwnProperty(category)) {
      filteredData[category] = data[category].filter(item => {
        return !excludeKeys.includes(item['data-key']);
      });
    }
  }
  return filteredData;
}


// Provider component that provides the structure data in the application
export function StructureProvider({ mock, children }) {
  // Initialize the structure data which is empty as long as the data is not retrieved yet
  const [structData, setStructData] = useState(null)

  let endpoint = config.api.endpoints.main_structureData

  // Update the state if the structure data is retrieved from the API
  useEffect(() => {
    async function fetchData() {
      const structResponse = await fetchWithToken(
        `${process.env.PUBLIC_URL}` + `${endpoint}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      )
      let structResponseData = await structResponse.json()
      setStructData(structResponseData)
    }

    // The update will only happen if the provider is not being mocked
    if (!mock) {
      fetchData()
    }

  }, [setStructData, mock])

  // Provide the mock data if this is given, otherwise the obtained structure data
  return (
    <StructureContext.Provider value={mock || structData}>
      {children}
    </StructureContext.Provider>
  )
}