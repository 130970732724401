import React from 'react'

// A component for showing and selecting only numeric categories for a variable
function CategoryOptionsNumeric(props) {
  // Filter out only the numeric categories from props.categories
  const numericCategories = props.categories.filter((category) =>
    !isNaN(parseFloat(category)) && isFinite(category)
  )

  // The settings contains a list with the names of the selected categories
  let settings = props.categoriesSelected

  // Event handlers
  const handleAllCategorySelection = handleAllCategorySelectionFunc.bind(this)
  const handleCategorySelection = handleCategorySelectionFunc.bind(this)

  // EVENT HANDLERS:

  // Allows user to deselect/select all numeric categories
  function handleAllCategorySelectionFunc(event) {
    // Check if the amount of categories currently selected
    // equals the total amount of numeric categories available.
    const newCategoriesSelected =
      settings.length === numericCategories.length ? [] : numericCategories
    settings = newCategoriesSelected
    props.onChange({
      target: {
        type: 'array',
        value: newCategoriesSelected,
        name: 'categoriesSelected' + props.dimension,
      },
    })
  }

  // Updates what categories have been selected, then passes it to the general form.
  function handleCategorySelectionFunc(event) {
    const target = event.target
    const value = target.checked
    const name = target.name

    let newCategoriesSelected
    if (value) {
      // Add the selected numeric category to the list
      const addition = numericCategories.find(
        (el) => String(el) === String(name)
      )
      newCategoriesSelected = [...settings, addition]
    } else {
      // Remove the selected numeric category from the list
      newCategoriesSelected = settings.filter(
        (el) => String(el) !== String(name)
      )
    }
    settings = newCategoriesSelected

    props.onChange({
      target: {
        type: 'array',
        value: newCategoriesSelected,
        name: 'categoriesSelected' + props.dimension,
      },
    })
  }

  // GENERAL FUNCTIONS:

  // Create the list of numeric category checkboxes
  function renderCategoryOptions() {
    // numericCategories is already filtered
    return numericCategories.map((category) => (
      <React.Fragment key={category}>
        <label>
          <input
            type="checkbox"
            name={category}
            checked={settings.includes(category)}
            onChange={handleCategorySelection}
          />
          &nbsp;&nbsp;{category}
        </label>
      </React.Fragment>
    ))
  }

  // RENDERER:

  return (
    <>
      <br />
      <b>{props.dimension}-axis (Numeric)</b>
      <br />
      <label>
        <input
          type="checkbox"
          name="selectAllCategories"
          checked={settings.length === numericCategories.length}
          onChange={handleAllCategorySelection}
        />
        &nbsp;&nbsp;Select all numeric categories
      </label>
      <div className="med-vis-category-options">{renderCategoryOptions()}</div>
    </>
  )
}

export default CategoryOptionsNumeric