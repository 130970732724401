function DocSharkSVG({ scale = 1 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="140 120 220 140"
      style={{ transform: `scale(${scale})` }} // Apply scale here
    >
      {/* Document Stack */}
      <rect x="170" y="160" width="140" height="50" fill="#e0e0e0" stroke="#333" strokeWidth="1" />
      <rect x="180" y="150" width="140" height="50" fill="#f5f5f5" stroke="#333" strokeWidth="1" />
      <rect x="190" y="140" width="140" height="50" fill="#ffffff" stroke="#333" strokeWidth="1" />

      {/* Magnifying Glass */}
      <circle cx="245" cy="160" r="25" fill="none" stroke="#666" strokeWidth="4" />
      <line x1="265" y1="180" x2="275" y2="200" stroke="#444" strokeWidth="6" />

      {/* Shark Emoji positioned to look through the glass */}
      <text x="278" y="165" fontFamily="Arial, sans-serif" fontSize="60" textAnchor="middle" dominantBaseline="middle">
        🦈
      </text>

      {/* Two-tone Centered Text */}
      <text x="250" y="240" fontFamily="Arial, sans-serif" fontSize="36" fontWeight="bold" textAnchor="middle">
        <tspan fill="#0D47A1">Doc</tspan>
        <tspan fill="#4FC3F7">Shark</tspan>
      </text>
    </svg>
  );
}

export default function DocSharkLogo({ scale = 0.3 }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center', // Centers horizontally
        alignItems: 'center', // Centers vertically
        width: '100%', // Take full width of parent
        maxHeight: '220px',
      }}
    >
      <DocSharkSVG scale={scale} />
    </div>
  );
}