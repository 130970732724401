// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import Chart from 'react-apexcharts'
import * as Colors from './Colors'

// pie chart component
function PieChart(props) {
  let settings = {
    options: {
      chart: {
        id: String(props.id),
        type: 'pie',
        toolbar: { show: false },
        fontFamily: 'Poppins, sans-serif',
        foreColor: 'var(--text-primary)',
        // events: {
        //   dataPointSelection: (event, chartContext, config) => {
        //     let variables = props.series.variables[config.dataPointIndex]
        //     props.onDataClick(variables)
        //   },
        // },
      },
      colors: Colors.pie_colors,
      dataLabels: {
        enabled: props.labels,
      },
      legend: {
        show: props.legend,
      },
      title: {
        text: props.name ? props.title : '',
        align: 'center',
        style: {
          fontWeight: '500',
          fontSize: '20px', // Increased font size for the title
          color: 'var(--text-primary)',
        },
      },
      labels: props.categories,
      noData: {
        text: `You can select the categories to be displayed.`,
      },
      plotOptions: { pie: { expandOnClick: false } },

      states: {
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
    },
    series: props.series.data,
  }
  // RENDERER:

  // renders a pie chart
  return (
    <Chart options={settings.options} series={settings.series} type="pie" />
  )
}

export default PieChart
