// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useContext, useState, useEffect } from 'react'
import config from '../../config/config'

// Create a new React context for the table utils data
// This is used to store the applied search, filters and sorters
const TableUtilsContext = React.createContext()

// Function that returns the context such that the data can be used in other components
export function useTableUtils() {
  return useContext(TableUtilsContext)
}

const defaultTableUtils = {
  search: '',
  filters: [
    {
      selected: '',
      input: [{ var: '', filterRange: 'from', custom: true }],
      filterType: '',
    },
  ],
  sorters: [{ selected: config.data.primary_key, order: 'asc' }],
}

export { defaultTableUtils }


// Provider component that provides the table utils data in the application
export function TableUtilsProvider({ children }) {
  const defaultSorters = [{ selected: config.data.primary_key, order: 'asc' }]
  // Initialize the state with an empty query and one unspecified filter and sorter item
  const [tableUtils, setTableUtils] = useState(defaultTableUtils)

  // Load filters and sorters from localStorage when the component mounts (not the search field)
  useEffect(() => {
    const savedTableUtils = localStorage.getItem('tableUtils')
    
    if (savedTableUtils) {
      const parsedTableUtils = JSON.parse(savedTableUtils)
      // Ensure search is always initialized to an empty string when loading from localStorage
      setTableUtils({
        ...parsedTableUtils,
        search: '', // Reset search to an empty string
      })
    }
  }, [])

  // Save only filters and sorters to localStorage whenever they change (ignore search field)
  useEffect(() => {
    if (tableUtils) {
      const { filters, sorters } = tableUtils
      localStorage.setItem('tableUtils', JSON.stringify({ filters, sorters }))
    }
  }, [tableUtils.filters, tableUtils.sorters])  // Only save when filters or sorters change


  return (
    <TableUtilsContext.Provider value={{ tableUtils, setTableUtils }}>
      {children}
    </TableUtilsContext.Provider>
  )
}
