import React, { useState } from 'react';
import HierarchicalViewer from './HierarchicalViewer';

const QuickOverview = ({ data, config }) => {
  const [activeView, setActiveView] = useState('atc');

  const viewOptions = [
    { id: 'atc', label: 'ATC Classification' },
    { id: 'substance', label: 'Products by Substance Type' },
    { id: 'date', label: 'Authorisation Timeline' },
    { id: 'country', label: 'Products by Country' }
  ];

  const viewConfigs = {
    atc: {
      title: "ATC Group Hierarchy",
      description: "Complete overview of all products categorized by ATC classification",
      config: {
        ...config,
        filterFn: ([name]) => name.includes('-')
      }
    },
    substance: {
      title: "Products by Substance Type",
      description: "Products organized by substance type and ATC classification",
      config: {
        ...config,
        sortFn: (a, b) => a.localeCompare(b)
      }
    },
    date: {
      title: "Authorisation Timeline",
      description: "Products organized by authorisation date",
      config: {
        ...config,
        sortFn: (a, b) => a.localeCompare(b)
      }
    },
    country: {
      title: "Products by Rapporteur Country",
      description: "Products organized by rapporteur country and authorisation year",
      config: {
        ...config,
        sortFn: (a, b) => a.localeCompare(b)
      }
    }
  };

  return (
    <div className="med-content-container">
      <div style={{ marginBottom: '20px' }}>
        <h1>Quick Overview 🔎</h1>
        <div style={{ 
          display: 'flex', 
          gap: '10px', 
          //backgroundColor: '#f5f5f5', 
          padding: '10px', 
          borderRadius: '8px' 
        }}>
          {viewOptions.map(option => (
            <button
              key={option.id}
              onClick={() => setActiveView(option.id)}
              style={{
                padding: '8px 16px',
                border: 'none',
                borderRadius: '4px',
                backgroundColor: activeView === option.id ? '#007bff' : '#fff',
                color: activeView === option.id ? '#fff' : '#333',
                cursor: 'pointer',
                fontWeight: activeView === option.id ? 'bold' : 'normal',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'all 0.2s ease'
              }}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
      
      <HierarchicalViewer
        data={data}
        viewType={activeView}
        title={viewConfigs[activeView].title}
        description={viewConfigs[activeView].description}
        config={viewConfigs[activeView].config}
      />
    </div>
  );
};

export default QuickOverview;