// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

// Filter data based on the given filters
function filterData(data, filters) {
  let updatedData = [...data]
  filters.forEach((item) => {
    switch (item.filterType) {
      case 'string':
        updatedData = textFilter(updatedData, item)
        break
      case 'number':
        updatedData = numFilter(updatedData, item)
        break
      case 'date':
        updatedData = dateFilter(updatedData, item)
        break
      case 'bool':
        updatedData = textFilter(updatedData, item)
        break
      default:
        break
    }
  })
  return updatedData
}

// Text filter
function textFilter(data, item) {
  // If no item is selected, then just return the data
  if (!item.selected) {
    return data
  }
  return data.filter((obj) => {
    return item.input.some((x) => {
      // Handle special missing value cases
      if (x.var === 'no_source') {
        return obj[item.selected].toString() === 'No source available';
      }
      if (x.var === 'unable') {
        return obj[item.selected].toString() === 'Unable to retrieve';
      }
      
      // If custom input is given, then the filter is only partial
      if (x.custom) {
        return obj[item.selected]
          .toString()
          .toLowerCase()
          .includes(x.var.toLowerCase())
      }
      // Otherwise they must match exactly
      return obj[item.selected].toString().toLowerCase() === x.var.toLowerCase()
    })
  })
}

// Numerical filter
function numFilter(data, item) {
  if (!item.selected) {
    return data; // No item selected, return the data as is
  }

  return data.filter((obj) => {
    // Check if the selected property is a valid number
    const selectedValue = parseFloat(obj[item.selected]);

    if (isNaN(selectedValue)) {
      // Handle non-numeric strings based on filterRange
      if (item.input.some((x) => x.filterRange === 'from' || x.filterRange === 'till' || x.filterRange === 'equal')) {
        return false; // Exclude non-numeric strings
      }
      return true; // Include non-numeric strings when filterRange is 'missing'
    }

    return item.input.every((x) => {
      // Filter the data based on the given numerical range
      if (x.filterRange === 'from') {
        return selectedValue >= x.var;
      }
      else if (x.filterRange === 'till') {
        return selectedValue <= x.var;
      }
      else if (x.filterRange === 'equal') {
        return selectedValue == x.var;
      } else if (x.filterRange === 'missing') {
        return isNaN(selectedValue); // Include only if it's a missing value
      } else {
        throw new Error('Filter range invalid');
      }
    });
  });
}

// Date filter
function dateFilter(data, item) {
  if (!item.selected) {
    return data; // No item selected, return the data as is
  }

  return data.filter((obj) => {
    const itemDate = new Date(obj[item.selected]);

    if (isNaN(itemDate)) {
      // Handle non-date values based on filterRange
      if (item.input.some((x) => x.filterRange === 'from' || x.filterRange === 'till' || x.filterRange === 'equal')) {
        return false; // Exclude non-date values
      }
      return true; // Include non-date values when filterRange is 'missing'
    }

    return item.input.every((x) => {
      const xDate = new Date(x.var);

      if (x.filterRange === 'from') {
        return xDate <= itemDate;
      } else if (x.filterRange === 'till') {
        return xDate >= itemDate;
      } else if (x.filterRange === 'equal') {
        // strip datetimes to date, due to different server time
        const datesAreEqual = (
          xDate.getFullYear() === itemDate.getFullYear() &&
          xDate.getMonth() === itemDate.getMonth() &&
          xDate.getDate() === itemDate.getDate()
        );
        return datesAreEqual;
      } else if (x.filterRange === 'missing') {
        return isNaN(itemDate); // Include only if it's a missing date
      } else {
        throw new Error('Invalid filter type');
      }
    });
  });
}

export default filterData
