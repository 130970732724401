// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useContext, useState } from 'react'
import config from '../../../config/config'

// Create a new React context for the column selection state of the tables
const ColumnSelectionContextSecondary = React.createContext()

// Function that returns the context such that the data can be used in other components
export function useColumnSelectionSecondary() {
  return useContext(ColumnSelectionContextSecondary)
}

export const defaultColumns = config.columns.secondary.default

// Provider component that provides the column selection state in the application
export function ColumnSelectionProviderSecondary({ children }) {
  // Set the default column selection state (in order from left to right)

  const [columnSelection, setColumnSelection] = useState(defaultColumns)
  
  return (
    <ColumnSelectionContextSecondary.Provider
      value={{ columnSelection, setColumnSelection }}
    >
      {children}
    </ColumnSelectionContextSecondary.Provider>
  )
}
