// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useState } from 'react'
import MedModal from '../../../../shared/MedModal'
import FilterMenu from './filter_menu/FilterMenu'
import './Menu.css'
import SortMenu from './sort_menu/SortMenu'
import config from '../../../../config/config'

// Function based component which renders the filter and sort menu
function Menu({ filters, sorters, update, categories }) {
  // Default filter object
  const filterObject = [
    {
      selected: '',
      input: [{ var: '', filterRange: 'from', custom: true }],
      filterType: '',
    },
  ]

  // Default sort object after clear button
  const sortObject = [{ selected: config.data.primary_key, order: 'asc' }]

  // Menu state variables
  const [localFilters, setFilters] = useState(filterObject)
  const [localSorters, setSorters] = useState(sortObject)
  const [showFilterModal, setFilterModalState] = useState(false)
  const [showSortModal, setSortModalState] = useState(false)

  // Handlers for opening and closing the modals
  const closeFilterModal = () => setFilterModalState(false)
  const closeSortModal = () => setSortModalState(false)
  const openFilterModal = () => setFilterModalState(true)
  const openSortModal = () => setSortModalState(true)

  // Handler for applying the filters and sorters
  const applyFilters = () => {
    update(localFilters, sorters)
    closeFilterModal()
  }

  const applySorters = () => {
    update(filters, localSorters)
    closeSortModal()
  }

  // Handler for clearing the filters and sorters
  const clearFilters = () => {
    setFilters(filterObject)
    update(filterObject, sorters)
  }

  const clearSorters = () => {
    setSorters(sortObject)
    update(filters, sortObject)
  }

  return (
    <>
      <button
        className="med-primary-solid med-bx-button med-data-button"
        onClick={openFilterModal}
      >
        <i className="bx bx-filter med-button-image" />
        Filter
      </button>
      <button
        className="med-primary-solid med-bx-button med-data-button"
        onClick={openSortModal}
      >
        <i className="bx bx-sort med-button-image" />
        Sort
      </button>

      <MedModal
        showModal={showFilterModal}
        closeModal={closeFilterModal}
        className="med-table-menu-modal"
      >
        <div>
          <FilterMenu
            filters={localFilters}
            setFilters={setFilters}
            defaultObj={filterObject}
            categories={categories}
          />
        </div>

        <div className="med-table-menu-filter-button-container">
          <button
            className="med-table-filter-button med-primary-solid"
            onClick={applyFilters}
          >
            Apply
          </button>
          <button
            className="med-table-filter-button med-table-menu-secondary-button"
            onClick={clearFilters}
          >
            Clear
          </button>
          <button
            className="med-table-filter-button med-table-menu-secondary-button"
            onClick={closeFilterModal}
          >
            Close
          </button>
        </div>
      </MedModal>

      <MedModal
        showModal={showSortModal}
        closeModal={closeSortModal}
        className="med-table-menu-modal"
      >
        <div>
          <SortMenu
            sorters={localSorters}
            setSorters={setSorters}
            defaultObj={sortObject}
          />
        </div>

        <div className="med-table-menu-filter-button-container">
          <button
            className="med-table-filter-button med-primary-solid"
            onClick={applySorters}
          >
            Apply
          </button>
          <button
            className="med-table-filter-button med-table-menu-secondary-button"
            onClick={clearSorters}
          >
            Clear
          </button>
          <button
            className="med-table-filter-button med-table-menu-secondary-button"
            onClick={closeSortModal}
          >
            Close
          </button>
        </div>
      </MedModal>
    </>
  )
}

export default Menu
