// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.css'
import config from '../../config/config'

// Function based component rendering the footer
function Footer() {
  return (
    // Footer components, gives the information to be placed in the footer in two sections placed
    // next to each other
    <footer className="med-footer">
      <section id="med-footer-organizations">
        <h2>{config.footer.organization_title}</h2>

        <ul className="med-footer-organization-list">
          {config.footer.organizations.map((org, index) => (
            <li key={index}>
              {' '}
              <a className="med-link" href={org.url}>
                {org.name}
              </a>
            </li>
          ))}
        </ul>
      </section>

      <section id="med-footer-information">
        <p className="med-footer-text">
        The European Medicines Regulatory Database, maintained by Utrecht University and CBG-MEB, 
        offers medicines regulation and marketing approval data under the XXX license. 
        Visit the <NavLink className="med-link" to="/info">info page</NavLink> for details.
        See our <NavLink className="med-link" to="/privacy_policy">privacy policy</NavLink> for information on data handling.
         &copy; {config.footer.copyright}
        </p>
      </section>
    </footer>
  )
}

export default Footer
