// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useContext } from 'react'
import { useCheckedStateSecondary } from './CheckedContextSecondary'
import { useDataSecondary } from './DataContextSecondary'
import config from '../../../config/config'

// Create a new React context for the selected medicines data
// The application uses this data to visualize the selected datapoints in the table and charts
export const SelectedContextSecondary = React.createContext()

// Function that returns the context such that the data can be used in other components
export function useSelectedDataSecondary() {
  return useContext(SelectedContextSecondary)
}

// Provider component that provides the selected medicines data in the application
export function SelectedProviderSecondary({ children }) {
  // We need the data and the current checked state
  const data = useDataSecondary()
  const { checkedState } = useCheckedStateSecondary()

  // Initialize the state with the selected datapoints
  const selectedData = data.filter((item) => {
    return checkedState[item[config.data.secondary_primary_key]]
  })

  return (
    <SelectedContextSecondary.Provider value={selectedData}>
      {children}
    </SelectedContextSecondary.Provider>
  )
}
