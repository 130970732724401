import { defaultTableUtils } from '../../shared/contexts/TableUtilsContext'
import { defaultTableUtils as defaultTableUtilsSecondary } from '../../shared/contexts/Secondary/TableUtilsContextSecondary'

export function useFilterSearch(tableUtils, setTableUtils, dataType = "main") {
  // Helper function to check if the filter is the default empty placeholder
  const isDefaultFilter = (filter) => {
    return (
      filter.selected === "" &&
      filter.input.length === 1 &&
      filter.input[0].var === ""
    )
  }

  // Helper functions to check active states
  const hasActiveFilters = () => {
    if (!tableUtils?.filters) return false;
    return tableUtils.filters.some(filter => !isDefaultFilter(filter));
  }

  const hasActiveSearch = () => {
    if (!tableUtils?.search) return false;
    return tableUtils.search.length > 0;
  }

  const hasActiveFiltersOrSearch = () => {
    return hasActiveFilters() || hasActiveSearch();
  }

  // Clear functions
  const clearFilters = () => {
    if (!setTableUtils || !tableUtils) return;
    const defaultUtils = dataType === "main" ? defaultTableUtils : defaultTableUtilsSecondary;
    setTableUtils({
      ...tableUtils,
      filters: defaultUtils.filters
    })
  }

  const clearSearch = () => {
    if (!setTableUtils || !tableUtils) return;
    const defaultUtils = dataType === "main" ? defaultTableUtils : defaultTableUtilsSecondary;
    setTableUtils({
      ...tableUtils,
      search: defaultUtils.search
    })
  }

  return {
    isDefaultFilter,
    hasActiveFilters,
    hasActiveSearch,
    hasActiveFiltersOrSearch,
    clearFilters,
    clearSearch
  }
}