import React, { useState, useRef, useEffect } from 'react';
import './DocSharkPage.css'
import '../../shared/shared.css'
import DocSharkLogo from './DocSharkLogo'
import DocSharkSearchGuide from './DocSharkSearchGuide'
import { performSearch, getSearchCount } from './DocSharkAPI';
import DocSharkResults from './DocSharkResults';
import config from '../../config/config'
import { useSelectedData } from '../../shared/contexts/SelectedContext'
import { useData } from '../../shared/contexts/DataContext';

const DocShark = () => {
  const [isSearchGuideOpen, setIsSearchGuideOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [displayedSearchQuery, setDisplayedSearchQuery] = useState('');
  const [selectedScope, setSelectedScope] = useState('all');
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStage, setLoadingStage] = useState(null); // Added loadingStage
  const [searchError, setSearchError] = useState(null);

  const allData = useData();
  const selectedData = useSelectedData();
  const dataPointAmount = selectedData.length;

  const openSearchGuide = () => {
    setIsSearchGuideOpen(true);
  };

  const closeSearchGuide = () => {
    setIsSearchGuideOpen(false);
  };

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      return; // Don't search with empty query
    }
    setDisplayedSearchQuery(searchQuery);
    
    setIsLoading(true);
    setLoadingStage('Searching...'); // Initial loading stage
    setSearchError(null);
    
    try {
      // Get the selected EU numbers for "current" scope, otherwise pass null for all
      const euNumbers = selectedScope === 'current' 
          ? selectedData.map(item => item.eu_pnumber.replace(/\//g, '-')) // Replace slashes with dashes
          : null;
          
      // Pass only selected file types if any are selected
      const fileTypes = selectedFileTypes.length > 0 ? selectedFileTypes : null;
      const results = await performSearch(searchQuery, euNumbers, fileTypes, setLoadingStage); // Pass setLoadingStage
      setSearchResults(results);
    } catch (error) {
      console.error("Search failed in component:", error);
      setSearchError("Failed to perform search. Please try again.");
    } finally {
      setIsLoading(false);
      setLoadingStage(null); // Reset loading stage
    }
  };

  const handleScopeChange = (e) => {
    setSelectedScope(e.target.value);
  };

  const handleFileTypeChange = (e) => {
    const fileType = e.target.value;
    setSelectedFileTypes(prev =>
      e.target.checked
        ? [...prev, fileType]
        : prev.filter(type => type !== fileType)
    );
  };

  return (
    <div className="med-info-content">
      <section className="med-content-container mt-6">
        <div className="flex flex-col items-center justify-center self-center">
          <DocSharkLogo />
          <p className="text-center">Search and explore regulatory documents</p>

          {/* Search container box */}
          <div className="docshark-search-container">
            <div className="form-container-box search-container">
              <input
                type="text"
                id="med-search-input"
                placeholder='("in vivo" OR "in vitro") AND "COVID-19"....'
                className="docshark-text-input med-text-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              />
              <button
                className="med-primary-solid med-bx-button med-search-button"
                onClick={handleSearch}
                disabled={isLoading}
              >
                {isLoading ? (
                  <i className="bx bx-loader-alt bx-spin med-button-image"></i>
                ) : (
                  <i className="bx bx-search med-button-image"></i>
                )}
                {isLoading ? loadingStage : 'Search Docs'} {/* Use loadingStage */}
              </button>
              <button
                className="med-primary-solid med-bx-button med-tour-button"
                onClick={openSearchGuide}
              >
                <i className="bx bx-info-circle med-button-image"></i>Guide
              </button>
            </div>
          </div>

          {/* Scope and File Type Selection box */}
          <div className="selection-box-container mt-6">
            {/* Scope Selection */}
            <div className="form-container-box scope-selection">
              <div className="scope-selection-container">
                <h2 className="text-center">Choose Scope:</h2>
                Which medicines to search documents for?
                <hr className="med-top-separator" />
                <div className="scope-options">
                  <label className="scope-option">
                    <input
                      type="radio"
                      name="scope"
                      value="all"
                      checked={selectedScope === 'all'}
                      onChange={handleScopeChange}
                    />
                    <span>All EMRD Medicines</span>
                  </label>
                  <label className="scope-option">
                    <input
                      type="radio"
                      name="scope"
                      value="current"
                      checked={selectedScope === 'current'}
                      onChange={handleScopeChange}
                    />
                    <span>Current EMRD selection ({dataPointAmount} {dataPointAmount === 1 ? config.data.singular : config.data.plural})</span>
                  </label>
                </div>
              </div>
            </div>

            {/* File Type Selection */}
            <div className="form-container-box file-type-selection">
              <div className="file-type-selection-container">
                <h2 className="text-center">Select Document Type(s):</h2>
                Which documents to search through?
                <hr className="med-top-separator" />
                <div className="file-type-options">
                  <label className="file-type-option">
                    <input
                      type="checkbox"
                      name="file-type"
                      value="epar"
                      checked={selectedFileTypes.includes('epar')}
                      onChange={handleFileTypeChange}
                    />
                    <span>EPAR Documents</span>
                  </label>
                  <label className="file-type-option">
                    <input
                      type="checkbox"
                      name="file-type"
                      value="decision"
                      checked={selectedFileTypes.includes('decision')}
                      onChange={handleFileTypeChange}
                    />
                    <span>Decision</span>
                  </label>
                  <label className="file-type-option">
                    <input
                      type="checkbox"
                      name="file-type"
                      value="annex"
                      checked={selectedFileTypes.includes('annex')}
                      onChange={handleFileTypeChange}
                    />
                    <span>Annex</span>
                  </label>
                </div>
              </div>
            </div>

            {/* Search Guide Popup */}
            <DocSharkSearchGuide
              isOpen={isSearchGuideOpen}
              onClose={closeSearchGuide}
            />
          </div>

          {/* Search Error Display */}
          {searchError && (
            <div className="form-container-box search-error-container mt-6">
              <div className="search-error-message">
                <i className="bx bx-error-circle"></i>
                <span>{searchError}</span>
              </div>
            </div>
          )}

          {/* Search Results Display */}
          {searchResults && !isLoading && (
            <div className="results-container">
              <DocSharkResults
                searchResults={searchResults}
                searchQuery={searchQuery}
                displayedSearchQuery={displayedSearchQuery}
                allData={allData}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default DocShark;