// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import './Table.css'
import Header from './components/Header'
import HeaderSecondary from './components/HeaderSecondary'
import ColumnAmount from './components/ColumnAmount'
import ColumnAmountSecondary from './components/ColumnAmountSecondary'
import Body from './components/Body'
import BodySecondary from './components/BodySecondary'
import { useTableUtils } from '../../../../shared/contexts/TableUtilsContext'
import { useTableUtilsSecondary } from '../../../../shared/contexts/Secondary/TableUtilsContextSecondary'
import ClearButtons from '../../../../shared/search/ClearButton'
import { useFilterSearch } from '../../../../shared/search/useFilterSearch'

// Function based component which renders the table
function Table(
  {
    data,
    select,
    amountPerPage,
    currentPage,
    sorters,
    setSorters,
    dataType
  }) {
  let { tableUtils, setTableUtils } = useTableUtils()
  let SecondaryContext = useTableUtilsSecondary()

  const utils = dataType === "main" ? tableUtils : SecondaryContext.tableUtils
  const setUtils = dataType === "main" ? setTableUtils : SecondaryContext.setTableUtils

  const {
    hasActiveFilters,
    hasActiveSearch,
    clearFilters,
    clearSearch
  } = useFilterSearch(utils, setUtils, dataType)

  if (dataType === "main") {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <ClearButtons
            tableUtils={utils}
            setTableUtils={setUtils}
            dataType={dataType}
            showWhenNoResults={false}
          />
          {/* <ColumnAmount /> */}
        </div>
        <table className={!select ? 'med-table med-table-select' : 'med-table'}>
          <Header
            data={data}
            select={select}
            sorters={sorters}
            setSorters={setSorters}
            dataType={dataType}
          />
          <Body
            data={data}
            select={select}
            amountPerPage={amountPerPage}
            currentPage={currentPage}
            dataType={dataType}
          />
        </table>
      </>
    )
  } else if (dataType === "secondary") {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <ClearButtons
            tableUtils={utils}
            setTableUtils={setUtils}
            dataType={dataType}
            showWhenNoResults={false}
          />
          {/* <ColumnAmountSecondary /> */}
        </div>
        <table className={!select ? 'med-table med-table-select' : 'med-table'}>
          <HeaderSecondary
            data={data}
            select={select}
            sorters={sorters}
            setSorters={setSorters}
            dataType={dataType}
          />
          <BodySecondary
            data={data}
            select={select}
            amountPerPage={amountPerPage}
            currentPage={currentPage}
            dataType={dataType}
          />
        </table>
      </>
    )
  }
}

export default Table
