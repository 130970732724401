// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useContext, useState, useEffect } from 'react'
import { fetchWithToken } from '../api'

// Create a new React context for the medicines data
const ScrapeDateContext = React.createContext()

// Function that returns the context such that the data can be used in other components
export function useScrapeDate() {
  return useContext(ScrapeDateContext)
}

// Provider component that provides the medicines data in the application
export function ScrapeDateProvider({ mock, children }) {
  // The structure data is needed to transform the data
  let endpoint = "/api/medicine/scrape-date/"
  
  // Initialize the state which is empty as long as the data is not retrieved yet
  const [data, setData] = useState("")

  // Update the state if the medicines data is fetched from the API
  useEffect(() => {
    async function fetchData() {
      const medResponse = await fetchWithToken(
        `${process.env.PUBLIC_URL}` + `${endpoint}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        }
      )
      const scrapeDate = await medResponse.json()

      let date = scrapeDate["scrape_date"]
      if(typeof date !== "undefined")
      {
        date = new Date(date).toLocaleDateString()
      }

      setData(date)
    }

    fetchData()
    
  }, [])


  // Provide the mock data if this is given, otherwise the obtained medicines data
  return (
    <ScrapeDateContext.Provider value={data}>{children}</ScrapeDateContext.Provider>
  )
}
