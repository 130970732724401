// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useContext, useState } from 'react'
import config from '../../../config/config'

// Create a new React context for the visualizations data
export const VisualsContextSecondary = React.createContext()

// Function that returns the context such that the data can be used in other components
export function useVisualsSecondary() {
  return useContext(VisualsContextSecondary)
}

// Provider component that provides the visualizations data in the application
export function VisualsProviderSecondary({ children }) {
  // Initialize with configured axis values
  const [visuals, setVisuals] = useState([
    {
      id: 1,
      chartType: 'bar',
      chartSpecificOptions: {
        xAxis: config.visualizations.secondary.xAxis,
        yAxis: config.visualizations.secondary.yAxis,
        horizontal: false,
      },
      legendOn: false,
      labelsOn: false,
      nameOn: false,
    },
  ])

  return (
    <VisualsContextSecondary.Provider value={{ visuals, setVisuals }}>
      {children}
    </VisualsContextSecondary.Provider>
  )
}
