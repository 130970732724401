// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

// Backend received data can be reformatted when displayed in the table
// Depending on the property/variable, different formatting may be applicable
export function dataToDisplayFormat({ entry, propt }) {
  switch (propt) {
    case 'decisiondate':
      return slashDateToStringDate(entry[propt])
    default:
      return entry[propt]
  }
}

export function slashDateToStringDate(date) {
  const defValue = date

  // Check if the date string matches the expected format (YYYY-MM-DD)
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateRegex.test(date)) {
    return defValue; // Return 'NA' for invalid date formats
  }

  const splittedDate = date.split('-');
  if (splittedDate.length !== 3) {
      return defValue; // return NA if split does not produce 3 parts
  }

  const year = splittedDate[0];
  const monthIndex = parseInt(splittedDate[1], 10) - 1; // Month is 0-indexed
  const day = parseInt(splittedDate[2], 10);

  if (isNaN(monthIndex) || isNaN(day)) {
    return defValue; // return NA if day or month is not a number
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (monthIndex < 0 || monthIndex >= months.length) {
    return defValue; // return NA if month index is out of range.
  }

  const month = months[monthIndex];

  const fullDate = day + ' ' + month + ' ' + year;
  return fullDate;
}
