// Function to generate a random color
export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Function to generate random colors for --med variables
export const getRandomColors = () => {
  const colors = [];
  for (let i = 0; i < 12; i++) {
    colors.push(getRandomColor());
  }
  return colors;
};

// Function to set --med CSS variables
export const setMedCSSVariables = (colors) => {
  const root = document.documentElement;
  root.style.setProperty('--med-main-background', colors[0]);
  root.style.setProperty('--med-shadow', colors[1]);
  root.style.setProperty('--med-content-background', colors[2]);
  root.style.setProperty('--med-content-border', colors[3]);
  root.style.setProperty('--med-nav-background', colors[4]);
  root.style.setProperty('--med-dialog-background', colors[5]);
  root.style.setProperty('--med-header-background', colors[6]);
  root.style.setProperty('--med-header-text', colors[7]);
  root.style.setProperty('--med-footer-background', colors[8]);
  root.style.setProperty('--med-footer-text', colors[9]);
  root.style.setProperty('--med-textlink-text', colors[10]);
  root.style.setProperty('--med-textlink-text-hover', colors[11]);
};

// Function to reset --med CSS variables to their default values
export const resetMedCSSVariables = () => {
  const root = document.documentElement;
  root.style.removeProperty('--med-main-background');
  root.style.removeProperty('--med-shadow');
  root.style.removeProperty('--med-content-background');
  root.style.removeProperty('--med-content-border');
  root.style.removeProperty('--med-nav-background');
  root.style.removeProperty('--med-dialog-background');
  root.style.removeProperty('--med-header-background');
  root.style.removeProperty('--med-header-text');
  root.style.removeProperty('--med-footer-background');
  root.style.removeProperty('--med-footer-text');
  root.style.removeProperty('--med-textlink-text');
  root.style.removeProperty('--med-textlink-text-hover');
}; 