// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useState, useEffect } from 'react';
import './Search.css';

function Search({ update, initial, tour }) {
  // We need a separate state for saving the query given in the textbox
  const [query, setQuery] = useState(initial);
  // Initialize the disco state, defaulting to false
  const [disco, setDisco] = useState(false);

  // Handler that applies the search by updating the query
  const applySearch = () => {
    if (String(query).includes("medisee")) {
      // Apply disco effect if disco state is true
      toggleDisco();
    } else {
    update(new String(query)) // new String() is required here to also update with same query string
    }
  }

  // Handler that updates the search after pressing enter key
  const handlerKeyDown = (e) => {
    if (e.key === 'Enter') {
      applySearch()
    }
  }

  // Handler that clears the search query
  const clearSearch = () => {
    setQuery('')
    update('')
  }

  // Disco code
  useEffect(() => {
    // Check if the disco state is saved in localStorage
    const savedDisco = localStorage.getItem('disco');
    // If the savedDisco is not null, set the disco state accordingly
    if (savedDisco !== null) {
      setDisco(savedDisco === 'true');
  
      // Apply disco effect if savedDisco is true
      if (savedDisco === 'true') {
        const header = document.querySelector('.med-main-header');
        if (header) {
          header.classList.add('disco');
        }
      }
    }
  }, []);

  // Handler to toggle the disco state
  const toggleDisco = () => {
    const newDisco = !disco;
    setDisco(newDisco);
    // Save the updated disco state to localStorage
    localStorage.setItem('disco', newDisco.toString());
  
    // Apply or remove disco effect based on the new disco state
    const header = document.querySelector('.med-main-header');
    if (header) {
      if (newDisco) {
        header.classList.add('disco');
        setMedCSSVariables(getRandomColors());
      } else {
        header.classList.remove('disco');
        resetMedCSSVariables();
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (disco) {
        setMedCSSVariables(getRandomColors());
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [disco]);

  const setMedCSSVariables = (colors) => {
    const root = document.documentElement;
    root.style.setProperty('--med-main-background', colors[0]);
    root.style.setProperty('--med-shadow', colors[1]);
    root.style.setProperty('--med-content-background', colors[2]);
    root.style.setProperty('--med-content-border', colors[3]);
    root.style.setProperty('--med-nav-background', colors[4]);
    root.style.setProperty('--med-dialog-background', colors[5]);
    root.style.setProperty('--med-header-background', colors[6]);
    root.style.setProperty('--med-header-text', colors[7]);
    root.style.setProperty('--med-footer-background', colors[8]);
    root.style.setProperty('--med-footer-text', colors[9]);
    root.style.setProperty('--med-textlink-text', colors[10]);
    root.style.setProperty('--med-textlink-text-hover', colors[11]);
  };
  
  // Function to reset --med CSS variables to their default values
  const resetMedCSSVariables = () => {
    const root = document.documentElement;
    root.style.removeProperty('--med-main-background');
    root.style.removeProperty('--med-shadow');
    root.style.removeProperty('--med-content-background');
    root.style.removeProperty('--med-content-border');
    root.style.removeProperty('--med-nav-background');
    root.style.removeProperty('--med-dialog-background');
    root.style.removeProperty('--med-header-background');
    root.style.removeProperty('--med-header-text');
    root.style.removeProperty('--med-footer-background');
    root.style.removeProperty('--med-footer-text');
    root.style.removeProperty('--med-textlink-text');
    root.style.removeProperty('--med-textlink-text-hover');
  };
  
  // Function to generate random colors for --med variables
  const getRandomColors = () => {
    const colors = [];
    for (let i = 0; i < 12; i++) {
      colors.push(getRandomColor());
    }
    return colors;
  };

  // Function to generate a random color
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div tour={tour} className="med-content-container">
      <div className="med-search-container">
        {
          /* Only show the X if there is actual input in the search bar */
          query && (
            <i
              className="bx bx-x med-search-close-icon"
              onClick={clearSearch}
              data-testid="search-close-icon"
            ></i>
          )
        }
        <input
          type="text"
          id="med-search-input"
          placeholder="Explore by Name, Code, Brand, Condition, etc. (COVID-19, EU/1/20, L01xx, impairment)"
          className="med-search-text-input med-text-input"
          onChange={(e) => setQuery(e.target.value)}
          value={query || ''}
          onKeyDown={handlerKeyDown}
        />
      </div>
      <button
        className="med-primary-solid med-bx-button med-search-button"
        onClick={applySearch}
      >
        <i className="bx bx-search med-button-image"></i>Search
      </button>
    </div>
  )
}

export default Search
