// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import { stringify } from 'uuid'
import { replace } from 'voca'
import CollapsibleButton from './CollapsibleButton'
import { CapitalizeFirstLetter } from './GeneralFunctions'


function ChangelogToText(changes)
{
    let text = ""

    for(var key1 in changes) {
        text += CapitalizeFirstLetter(changes[key1]["change"]) + ": "
        text += changes[key1]["header"].replace(/(\r\n|\n|\r)/g, "") + "\n"

        if (["section_text"] in changes[key1])
        {
            text += "Section Text: " + changes[key1]["section_text"].replace(/(\r\n|\n|\r)/g, "") + "\n"
        }
        if (["old_content"] in changes[key1])
        {
            text += "Old Content: " + changes[key1]["old_content"].replace(/(\r\n|\n|\r)/g, "") + "\n"
        }
        if (["new_content"] in changes[key1])
        {
            text += "New Content: " + changes[key1]["new_content"].replace(/(\r\n|\n|\r)/g, "") + "\n"
        }

        text += "\n"
     }
    
    return text
}

function CreateChangelog({json}) {

    let last_updated = json["last_updated"]
    const myArray = last_updated.split(".")
    let noMicroseconds = myArray[0]

    let changelog = json["changelogs"].map((change) => {
        return (
            <CollapsibleButton
                key={change["old_file"]}
                name={change["old_file"] + "    →    " + change["new_file"]}
                content={ChangelogToText(change["changes"])}
            />
        )
    })

    return (
        <>
             <h2 className="med-header">Last Updated: {noMicroseconds}</h2>
            <div>{changelog}</div>
        </>
        )
}

export default CreateChangelog