// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

// external imports
import { changeDpiDataUrl } from 'changedpi'

// event handler for exporting the visualization to png
export default function HandlePNGExport(id, title, ApexCharts) {
  const fileName = 'Graph_' + title;
  const fileType = '.png';
  const downloadLink = document.createElement('a');

  ApexCharts.exec(String(id), 'dataURI', { scale: 3.5 }).then(({ imgURI }) => {
    // Add the watermark
    addWatermarkPNG(imgURI, "Made using EMRD").then(watermarkedURI => {
      // changes the dpi of the visualization to 300
      downloadLink.href = changeDpiDataUrl(watermarkedURI, 300);
      downloadLink.download = fileName + fileType;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  });
}

function addWatermarkPNG(pngDataURI, watermarkText) {
  return new Promise((resolve) => {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let img = new Image();

    img.onload = function() {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);

      // Adjust these values to make the watermark more visible
      context.font = '52px Arial'; // Increased font size
      context.fillStyle = 'rgba(128, 128, 128, 0.7)'; // Slightly darker and more opaque gray
      context.globalAlpha = 1; // Full opacity
      context.textAlign = 'right';
      context.textBaseline = 'bottom'; // Align to the bottom of the text
      context.fillText(watermarkText, canvas.width * 0.98, canvas.height * 0.98); // Move the text closer to the edge

      let newPngDataURI = canvas.toDataURL('image/png');
      resolve(newPngDataURI);
    };

    img.src = pngDataURI;
  });
}
