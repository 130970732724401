import React from 'react';
import { useFilterSearch } from './useFilterSearch';

function ClearButtons({ 
  tableUtils,
  setTableUtils,
  dataType = "main",
  showWhenNoResults = false,
  filteredRows = null 
}) {
  const {
    hasActiveFilters,
    hasActiveSearch,
    clearFilters,
    clearSearch
  } = useFilterSearch(tableUtils, setTableUtils, dataType);

  // Only show if we have no results (when showWhenNoResults is true)
  // or always show when showWhenNoResults is false
  const shouldShow = showWhenNoResults ? filteredRows === 0 : true;

  // Check if we have any active filters or search
  const hasActive = hasActiveFilters() || hasActiveSearch();

  if (!shouldShow || !hasActive) {
    return null;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
        {hasActiveFilters() && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Active filters found. Clear them?</span>
            <button
              className="med-primary-solid med-bx-button med-clear-button"
              onClick={clearFilters}
            >
              <i className="bx bx-filter med-button-image"></i>
              Clear Filters
            </button>
          </div>
        )}
        {hasActiveSearch() && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Active search found. Clear it?</span>
            <button
              className="med-primary-solid med-bx-button med-clear-button"
              onClick={clearSearch}
            >
              <i className="bx bx-search med-button-image"></i>
              Clear Search
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClearButtons;