// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useState, useEffect } from 'react';
import './Header.css';
import logo from '../../images/logo.svg';
import { Link } from 'react-router-dom';

function Header() {
  const [theme, setTheme] = useState(() => localStorage.getItem('theme') || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'));

  useEffect(() => {
    // Store the selected theme in localStorage
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <header className="med-main-header">
      <Link to="/">
        <div>
          <img
            src={logo}
            alt="Logo of the European Database For Pharmaceutical Policy website"
            id="header__logo"
          />
        </div>
      </Link>
      <h1>European Medicines Regulatory Database</h1>

      {/* Wrapper to place both buttons together */}
      <div className="header-buttons">
        {/* Animated Theme Toggle Button */}
        <button className={`theme-toggle ${theme}`} onClick={toggleTheme} aria-label="Toggle theme">
          <div className="icon-wrapper">
            {theme === 'light' ? '🌙' : '☀️'}
          </div>
        </button>

        {/* GitHub Button */}
        <div className="med-header-github-button">
          <a
            href="https://github.com/cbg-meb/medicines-regulatory-db/issues"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="40px"
              height="40px"
              viewBox="0 0 40 40"
              id="github_button"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>
                Incorrect data or bug in website? &#10;&#13;Click here to raise a
                Github issue
              </title>
              <g id="exclamation-circle-Regular" fill="var(--ui)">
                <path
                  id="exclamation-circle-Regular-2"
                  data-name="exclamation-circle-Regular"
                  d="M20 3.75A16.25 16.25 0 1 0 36.25 20 16.268 16.268 0 0 0 20 3.75Zm0 30A13.75 13.75 0 1 1 33.75 20 13.765 13.765 0 0 1 20 33.75ZM21.667 26.667a1.667 1.667 0 1 1 -1.667 -1.667A1.667 1.667 0 0 1 21.667 26.667Zm-2.917 -6.667V13.333a1.25 1.25 0 0 1 2.5 0v6.667a1.25 1.25 0 0 1 -2.5 0Z"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;