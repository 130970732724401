import React from 'react'
import '../../shared/shared.css'
import Search from '../../shared/search/Search'
import { useNavigate, Link } from 'react-router-dom'
import { useTableUtils } from '../../shared/contexts/TableUtilsContext'
import config from '../../config/config'

// Function based component rendering the Privacy Policy page
function PrivacyPage() {
  const navigate = useNavigate()
  const { tableUtils, setTableUtils } = useTableUtils()

  // Set the query in the utils context and navigate to the datapage
  const search = (query) => {
    setTableUtils({ ...tableUtils, search: query })
    navigate(config.routes.main.data)
  }

  return (
    // Privacy page content, contains article containers
    <div className="med-info-content">
      <Search update={search} />
      <section className="med-content-container">
        <h1>Privacy Policy</h1>
        <p>Effective Date - March 1st 2025</p>
        <hr className="med-top-separator" />
        
        

        <p>Your privacy is important to us. This Privacy Policy explains how we collect, use, and protect your data when you use our website.</p>

        <h2>1. Information We Collect</h2>
        <p>Our website collects search queries that you enter into our search bars. We do not track or store any personal information, such as your name, email, or IP address. The data we collect is limited to the text you submit as a search query.</p>

        <h2>2. Use of Search Queries</h2>
        <p>The search queries you enter may be stored temporarily for the purpose of:</p>
        <ul>
          <li>Improving the accuracy and relevance of search results.</li>
          <li>Analyzing popular search terms and trends to enhance the user experience.</li>
        </ul>
        <p>We do not use search queries to create a profile of individual users, and we do not track your activity across the web.</p>

        <h2>3. Data Retention</h2>
        <p>Search queries are retained for a limited time (180 days) and are then automatically deleted from our servers. This data retention policy ensures that we minimize the amount of data we hold and keep it relevant to the purpose of improving our website.</p>

        <h2>4. Data Security</h2>
        <p>We employ reasonable security measures to safeguard stored search queries against unauthorized access. However, given the inherent limitations of internet data transmission, absolute security cannot be guaranteed.</p>

        <h2>5. Cookies</h2>
        <p>Our website may use cookies to improve your experience. Cookies are small data files stored on your device that help us remember your preferences and enhance the functionality of our site. You can disable cookies through your browser settings, but doing so may affect your experience on our website.</p>

        <h2>6. Your Rights</h2>
        <p>Although we do not collect personally identifiable information, if you wish to inquire about the search data we store or request its deletion, you can contact us using the contact information provided below.</p>

        <h2>7. Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. When we make changes, we will update the "Effective Date" at the top of this page.</p>

        <h2>8. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy or would like to contact us regarding your data, please reach out to us at:</p>
        <p>Email: [XXX Email Address]</p>
      </section>
    </div>
  )
}

export default PrivacyPage