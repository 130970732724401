// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useState, useEffect } from 'react';
import './Search.css';
import config from '../../config/config';
import { getRandomColors, setMedCSSVariables, resetMedCSSVariables } from '../disco/disco';

function Search({ update, initial, tour, dataType = 'main' }) {
  // Initialize query state with initial value or empty string
  const [query, setQuery] = useState(initial || '');
  // Initialize the disco state, defaulting to false
  const [disco, setDisco] = useState(false);

  // Get the appropriate placeholder based on dataType
  const getPlaceholder = () => {
    return dataType === 'main' 
      ? config.data.search_placeholder 
      : config.data.secondary_search_placeholder;
  };

  // Handler that applies the search by updating the query
  const applySearch = () => {
    const searchQuery = query || '';  // Ensure query is never undefined
    if (String(searchQuery).includes("medisee")) {
      // Apply disco effect if disco state is true
      toggleDisco();
    } else {
    update(new String(searchQuery)) // new String() is required here to also update with same query string
    }
  }

  // Handler that updates the search after pressing enter key
  const handlerKeyDown = (e) => {
    if (e.key === 'Enter') {
      applySearch()
    }
  }

  // Handler that clears the search query
  const clearSearch = () => {
    setQuery('')
    update('')
  }

  // Update query when initial prop changes
  useEffect(() => {
    setQuery(initial || '');
  }, [initial]);

  // Disco code
  useEffect(() => {
    // Check if the disco state is saved in localStorage
    const savedDisco = localStorage.getItem('disco');
    // If the savedDisco is not null, set the disco state accordingly
    if (savedDisco !== null) {
      setDisco(savedDisco === 'true');
  
      // Apply disco effect if savedDisco is true
      if (savedDisco === 'true') {
        const header = document.querySelector('.med-main-header');
        if (header) {
          header.classList.add('disco');
        }
      }
    }
  }, []);

  // Handler to toggle the disco state
  const toggleDisco = () => {
    const newDisco = !disco;
    setDisco(newDisco);
    // Save the updated disco state to localStorage
    localStorage.setItem('disco', newDisco.toString());
  
    // Apply or remove disco effect based on the new disco state
    const header = document.querySelector('.med-main-header');
    if (header) {
      if (newDisco) {
        header.classList.add('disco');
        setMedCSSVariables(getRandomColors());
      } else {
        header.classList.remove('disco');
        resetMedCSSVariables();
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (disco) {
        setMedCSSVariables(getRandomColors());
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [disco]);

  return (
    <div tour={tour} className="med-content-container med-search-sticky">
      <div className="med-search-container">
        {
          /* Only show the X if there is actual input in the search bar */
          query && (
            <i
              className="bx bx-x med-search-close-icon"
              onClick={clearSearch}
              data-testid="search-close-icon"
            ></i>
          )
        }
        <input
          type="text"
          id="med-search-input"
          placeholder={getPlaceholder()}
          className="med-search-text-input med-text-input"
          onChange={(e) => setQuery(e.target.value || '')}
          value={query}
          onKeyDown={handlerKeyDown}
        />
      </div>
      <button
        className="med-primary-solid med-bx-button med-search-button"
        onClick={applySearch}
      >
        <i className="bx bx-search med-button-image"></i>Search
      </button>
    </div>
  )
}

export default Search
