import React, { useState, useEffect, useRef, useMemo } from 'react';
import './DocSharkResults.css';
import { getHighlights } from './DocSharkAPI';

const DocSharkResults = ({ searchResults, searchQuery, displayedSearchQuery, allData }) => {
  const { search_time, total_hits, results } = searchResults;
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [highlightsMap, setHighlightsMap] = useState({});
  const [loadingHighlights, setLoadingHighlights] = useState({});
  const [highlightErrors, setHighlightErrors] = useState({});

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Filtering and sorting state
  const [filterText, setFilterText] = useState('');
  const [sortOption, setSortOption] = useState('euNumber'); // euNumber, name, hits
  const [sortDirection, setSortDirection] = useState('desc'); // asc or desc

  // Search timer
  const [debouncedFilterText, setDebouncedFilterText] = useState(filterText);
  const debounceTimer = useRef(null);

  // Group results by EU P-Number
  const groupedResults = useMemo(() => {
    return results.reduce((groups, result) => {
      const euNumber = result.eu_pnumber;
      if (!groups[euNumber]) {
        groups[euNumber] = [];
      }
      groups[euNumber].push(result);
      return groups;
    }, {});
  }, [results]);

  // Count results by file type
  const fileTypeCounts = useMemo(() => {
    return results.reduce((counts, result) => {
      const fileType = result.file_type === 'Nan' ? 'other' : result.file_type;
      counts[fileType] = (counts[fileType] || 0) + 1;
      return counts;
    }, {});
  }, [results]);

  useEffect(() => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      setDebouncedFilterText(filterText);
    }, 300); // Adjust debounce delay (300ms) as needed

    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [filterText]);

  // Count total unique medicines (EU numbers)
  const totalMedicines = useMemo(() => Object.keys(groupedResults).length, [groupedResults]);

  // Filtered and sorted groups for pagination
  const filteredAndSortedGroups = useMemo(() => {
    // Create an array of objects with eu number and document count
    const groupsArray = Object.entries(groupedResults).map(([euNumber, documents]) => {
      // Find the medicine name for this EU number
      const formattedEuNumber = euNumber.replace(/-/g, '/');
      const medicineItem = allData?.find(
        item => item.eu_pnumber.replace('-', /\//g) === formattedEuNumber
      );
      const medicineName = medicineItem?.eu_brand_name_current || '';
      const activeSubstance = medicineItem?.active_substance_current || '';

      return {
        euNumber,
        documents,
        documentCount: documents.length,
        medicineName,
        activeSubstance,
        displayName: `${formattedEuNumber} - ${medicineName} (${activeSubstance})`
      };
    });

    const preprocessedGroupsArray = groupsArray.map(group => ({
      ...group,
      lowerEuNumber: group.euNumber.toLowerCase(),
      lowerMedicineName: group.medicineName.toLowerCase(),
      lowerActiveSubstance: group.activeSubstance.toLowerCase()
    }));

    // Filter groups based on debouncedFilterText
    const filtered = debouncedFilterText
      ? preprocessedGroupsArray.filter(group =>
        group.lowerEuNumber.includes(debouncedFilterText.toLowerCase()) ||
        group.lowerMedicineName.includes(debouncedFilterText.toLowerCase()) ||
        group.lowerActiveSubstance.includes(debouncedFilterText.toLowerCase())
      )
      : preprocessedGroupsArray;

    // Sort the filtered groups
    const sorted = [...filtered].sort((a, b) => {
      let comparison = 0;

      if (sortOption === 'euNumber') {
        // Custom sorting for EU numbers based on the last digits
        const lastDigitsA = a.euNumber.substring(a.euNumber.lastIndexOf('-') + 1);
        const lastDigitsB = b.euNumber.substring(b.euNumber.lastIndexOf('-') + 1);
        comparison = lastDigitsA.localeCompare(lastDigitsB, undefined, { numeric: true });
      } else if (sortOption === 'name') {
        comparison = a.medicineName.localeCompare(b.medicineName);
      } else if (sortOption === 'hits') {
        comparison = a.documentCount - b.documentCount;
      }

      return sortDirection === 'asc' ? comparison : -comparison;
    });

    return sorted;
  }, [groupedResults, debouncedFilterText, sortOption, sortDirection, allData]);

  // Reset to first page when debouncedFilterText changes
  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedFilterText, sortOption, sortDirection]);

  // Paginate the filtered and sorted groups
  const paginatedGroups = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredAndSortedGroups.slice(startIndex, endIndex);
  }, [filteredAndSortedGroups, currentPage, itemsPerPage]);

  // Calculate total pages
  const totalPages = useMemo(() =>
    Math.ceil(filteredAndSortedGroups.length / itemsPerPage),
    [filteredAndSortedGroups, itemsPerPage]
  );

  // Reset to first page when filter changes
  useEffect(() => {
    setCurrentPage(1);
  }, [filterText, sortOption, sortDirection]);

  // Load highlights for all documents in a group
  const loadGroupHighlights = async (euNumber, documents) => {
    // Set loading state for all documents in this group
    const newLoadingState = { ...loadingHighlights };
    documents.forEach(doc => {
      newLoadingState[doc.filename] = true;
    });
    setLoadingHighlights(newLoadingState);

    // Clear any previous errors
    const newErrorState = { ...highlightErrors };
    documents.forEach(doc => {
      delete newErrorState[doc.filename];
    });
    setHighlightErrors(newErrorState);

    // Fetch highlights for each document in parallel
    const highlightPromises = documents.map(async (document) => {
      try {
        const highlightsData = await getHighlights(document.filename, searchQuery);
        return {
          fileName: document.filename,
          highlights: highlightsData.highlights || []
        };
      } catch (error) {
        console.error(`Failed to fetch highlights for ${document.filename}:`, error);
        setHighlightErrors(prev => ({
          ...prev,
          [document.filename]: "Could not load highlights for this document."
        }));
        return {
          fileName: document.filename,
          highlights: []
        };
      }
    });

    try {
      const results = await Promise.all(highlightPromises);

      // Update highlights map with results
      const newHighlightsMap = { ...highlightsMap };
      results.forEach(result => {
        newHighlightsMap[result.fileName] = result.highlights;
      });
      setHighlightsMap(newHighlightsMap);
    } catch (error) {
      console.error("Error loading highlights:", error);
    }

    // Clear loading state for all documents in this group
    const finalLoadingState = { ...loadingHighlights };
    documents.forEach(doc => {
      finalLoadingState[doc.filename] = false;
    });
    setLoadingHighlights(finalLoadingState);
  };

  const toggleGroup = async (euNumber) => {
    // If we're closing the group, just set expanded group to null
    if (expandedGroup === euNumber) {
      setExpandedGroup(null);
      return;
    }

    // Otherwise expand and load highlights
    setExpandedGroup(euNumber);

    // Load highlights for all documents in this group
    const documentsInGroup = groupedResults[euNumber] || [];
    await loadGroupHighlights(euNumber, documentsInGroup);
  };

  const formatEuNumber = (euNumber, allData) => {
    euNumber = euNumber.replace(/-/g, '/'); // Replace dashes with slashes for display

    if (!allData || allData.length === 0) {
      return euNumber; // Return only the EU number if no selected data
    }

    const item = allData.find(item => item.eu_pnumber.replace('-', /\//g) === euNumber);

    if (item) {
      return `${euNumber} - ${item.eu_brand_name_current} (${item.active_substance_current})`;
    } else {
      return euNumber; // Return only the EU number if no matching item found
    }
  };

  // Handle sort change
  const handleSortChange = (option) => {
    if (sortOption === option) {
      // Toggle direction if clicking the same option
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new sort option with default direction
      setSortOption(option);
      setSortDirection(option === 'hits' ? 'desc' : 'asc'); //Default to desc for hits
    }
  };

  // Generate pagination controls
  const renderPagination = () => {
    const pages = [];

    // Always show first page
    pages.push(
      <button
        key={1}
        onClick={() => setCurrentPage(1)}
        className={`pagination-button ${currentPage === 1 ? 'active' : ''}`}
        disabled={currentPage === 1}
      >
        1
      </button>
    );

    // If we're far enough from the start, show ellipsis
    if (currentPage > 4) {
      pages.push(<span key="ellipsis1" className="pagination-ellipsis">...</span>);
    }

    // Show pages around current page
    for (let i = Math.max(2, currentPage - 2); i <= Math.min(totalPages - 1, currentPage + 2); i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`pagination-button ${currentPage === i ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    // If we're far enough from the end, show ellipsis
    if (currentPage < totalPages - 3) {
      pages.push(<span key="ellipsis2" className="pagination-ellipsis">...</span>);
    }

    // Always show last page if there's more than 1 page
    if (totalPages > 1) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => setCurrentPage(totalPages)}
          className={`pagination-button ${currentPage === totalPages ? 'active' : ''}`}
          disabled={currentPage === totalPages}
        >
          {totalPages}
        </button>
      );
    }

    return (
      <div className="pagination-container">
        <button
          className="pagination-button pagination-nav"
          onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
          disabled={currentPage === 1}
        >
          <i className='bx bx-chevron-left'></i>
        </button>

        {pages}

        <button
          className="pagination-button pagination-nav"
          onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
          disabled={currentPage === totalPages}
        >
          <i className='bx bx-chevron-right'></i>
        </button>

        <div className="pagination-info">
          Page {currentPage} of {totalPages || 1}
        </div>

        <select
          className="items-per-page-select"
          value={itemsPerPage}
          onChange={(e) => {
            setItemsPerPage(Number(e.target.value));
            setCurrentPage(1); // Reset to first page when changing items per page
          }}
        >
          <option value={10}>10 per page</option>
          <option value={25}>25 per page</option>
          <option value={50}>50 per page</option>
          <option value={100}>100 per page</option>
        </select>
      </div>
    );
  };

  return (
    <div className="research-results-box search-results-container">
      <div className="search-results-summary">
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
          <h2 style={{ margin: 0 }}>Search Results: "{displayedSearchQuery}"</h2>
          <span>(
            {search_time && search_time >= 0.001
              ? search_time.toFixed(3)
              : "<0.001"}s
            )</span>
        </span>
        <div className="results-overview">
          <div className="total-results-grid">
            <div className="total-result-item">
              <span className="result-count">{totalMedicines}</span>
              <span className="result-label"><h2>Medicines with Hits</h2></span>
            </div>
            <div className="total-result-item">
              <span className="result-count">{total_hits}</span>
              <span className="result-label"><h2>Documents Found</h2></span>
            </div>
          </div>
          <div className="results-by-type">
            {['epar', 'decision', 'annex', 'other'].map(fileType => (
              fileTypeCounts[fileType] && (
                <div className="file-type-result" key={fileType}>
                  <span className={`file-type-bubble file-type-${fileType.toLowerCase()}`}>
                    {fileTypeCounts[fileType]}
                  </span>
                  <span className="file-type-label">{fileType}</span>
                </div>
              )
            ))}
          </div>
        </div>
      </div>

      {/* Filter and sort controls */}
      <div className="results-controls">
        <div className="filter-container">
          <div>
            <i className='med-search-input'></i>
            <input
              type="text"
              placeholder="Filter medicines by name, substance or ID..."
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className="filter-input"
            />
            {filterText && (
              <button
                className="clear-filter"
                onClick={() => setFilterText('')}
              >
                <i className='bx bx-x'></i>
              </button>
            )}
          </div>
        </div>

        <div className="sort-container">
          <span className="sort-label">Sort by:</span>
          <div className="sort-buttons">
            <button
              className={`sort-button ${sortOption === 'euNumber' ? 'active' : ''}`}
              onClick={() => handleSortChange('euNumber')}
            >
              EU Number
              {sortOption === 'euNumber' && (
                <i className={`bx ${sortDirection === 'asc' ? 'bx-sort-up' : 'bx-sort-down'}`}></i>
              )}
            </button>

            <button
              className={`sort-button ${sortOption === 'name' ? 'active' : ''}`}
              onClick={() => handleSortChange('name')}
            >
              Medicine Name
              {sortOption === 'name' && (
                <i className={`bx ${sortDirection === 'asc' ? 'bx-sort-up' : 'bx-sort-down'}`}></i>
              )}
            </button>

            <button
              className={`sort-button ${sortOption === 'hits' ? 'active' : ''}`}
              onClick={() => handleSortChange('hits')}
            >
              Document Count
              {sortOption === 'hits' && (
                <i className={`bx ${sortDirection === 'asc' ? 'bx-sort-up' : 'bx-sort-down'}`}></i>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Display filtered/sorted medicine count */}
      <div className="filtered-results-info">
        Showing {paginatedGroups.length} of {filteredAndSortedGroups.length} medicines
        {filterText && (
          <span> filtered by "{filterText}"</span>
        )}
      </div>

      {/* Results pagination - top */}
      {totalPages > 1 && (
        <div className="pagination-top">
          {renderPagination()}
        </div>
      )}

      {/* Results by EU number */}
      <div className="results-by-eu-number">
        {paginatedGroups.length > 0 ? (
          paginatedGroups.map(({ euNumber, documents }) => {
            // Count document types within this EU number
            const groupTypeCounts = documents.reduce((counts, result) => {
              const fileType = result.file_type === 'Nan' ? 'other' : result.file_type;
              counts[fileType] = (counts[fileType] || 0) + 1;
              return counts;
            }, {});

            return (
              <div className="eu-number-group" key={euNumber}>
                <div
                  className="eu-number-header"
                  onClick={() => toggleGroup(euNumber)}
                >
                  <div className="eu-number-title">
                    <i className={`bx ${expandedGroup === euNumber ? 'bx-chevron-down' : 'bx-chevron-right'}`}></i>
                    <h3>{formatEuNumber(euNumber, allData)}</h3>
                  </div>
                  <div className="document-type-indicators">
                    {['epar', 'decision', 'annex', 'other'].map(type => (
                      groupTypeCounts[type] && (
                        <div className="document-type-bubble" key={type}>
                          <span className={`bubble-count bubble-count-${type}`}>{groupTypeCounts[type]}</span>
                          <span className="bubble-label">{type}</span>
                        </div>
                      )
                    ))}
                  </div>
                </div>

                {expandedGroup === euNumber && (
                  <div className="eu-number-documents">
                    {documents.map((result, index) => (
                      <div className="document-container" key={index}>
                        <div className="document-result">
                          <div className={`document-type-tag document-type-tag-${result.file_type === 'Nan' ? 'other' : result.file_type}`}>
                            {result.file_type === 'Nan' ? 'Other' : result.file_type}
                          </div>
                          <div className="document-details">
                            <div className="document-title">{result.real_name}</div>
                            <a
                              href={result.file_link}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="document-link"
                            >
                              <i className='bx bx-file-blank'></i> View Document
                            </a>
                          </div>
                        </div>

                        {/* Document Highlights Section - Always shown for all documents */}
                        <div className="document-highlights">
                          <h4>Document Highlights*</h4>*note highlights are only and indication and might not always contain the exact search query used
                          {loadingHighlights[result.filename] && (
                            <div className="highlights-loading">
                              <i className='bx bx-loader-alt bx-spin'></i> Loading highlights...
                            </div>
                          )}

                          {highlightErrors[result.filename] && (
                            <div className="highlights-error">
                              <i className='bx bx-error-circle'></i> {highlightErrors[result.filename]}
                            </div>
                          )}

                          {!loadingHighlights[result.filename] && !highlightErrors[result.filename] &&
                            (!highlightsMap[result.filename] || highlightsMap[result.filename].length === 0) && (
                              <div className="no-highlights">
                                No highlights found for this document.
                              </div>
                            )}

                          {!loadingHighlights[result.filename] && !highlightErrors[result.filename] &&
                            highlightsMap[result.filename] && highlightsMap[result.filename].length > 0 && (
                              <div className="highlights-list">
                                {highlightsMap[result.filename].slice(0, 10).map((highlight, idx) => (
                                  <div className="highlight-item" key={idx}>
                                    <div className="highlight-content" dangerouslySetInnerHTML={{ __html: highlight }}></div>
                                  </div>
                                ))}
                              </div>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="no-results-message">
            <i className='bx bx-search-alt'></i>
            <p>No medicines match your filter criteria. Try adjusting your search.</p>
            {filterText && (
              <button
                className="clear-filter-button"
                onClick={() => setFilterText('')}
              >
                Clear Filter
              </button>
            )}
          </div>
        )}
      </div>

      {/* Results pagination - bottom */}
      {totalPages > 1 && (
        <div className="pagination-bottom">
          {renderPagination()}
        </div>
      )}
    </div>
  );
};

export default DocSharkResults;