import { useData } from '../../shared/contexts/DataContext.js'
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { DataToString } from '../components/GeneralFunctions.js'
import config from '../../config/config.js'

const address = config.routes.main.details

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const transformDateData = (data) => {
  const hierarchy = {};

  data.forEach(product => {
    if (!product.eu_aut_date) return;

    const [year, month] = product.eu_aut_date.split('-');
    const monthName = monthNames[parseInt(month) - 1];

    // Create year level
    if (!hierarchy[year]) {
      hierarchy[year] = { count: 0, children: {} };
    }
    hierarchy[year].count += 1;

    // Create month level with products directly
    if (!hierarchy[year].children[monthName]) {
      hierarchy[year].children[monthName] = {
        count: 0,
        products: []
      };
    }
    hierarchy[year].children[monthName].count += 1;

    // Add product details with authorization year
    hierarchy[year].children[monthName].products.push({
      active_substance_current: product.active_substance_current,
      eu_pnumber: product.eu_pnumber,
      eu_brand_name_current: product.eu_brand_name_current,
      eu_aut_date: year
    });
  });

  return hierarchy;
};

const transformCountryData = (data) => {
  const hierarchy = {};

  data.forEach(product => {
    if (!product.rapp_country || !product.eu_aut_date) return;

    const country = product.rapp_country;
    const [year] = product.eu_aut_date.split('-');

    // Create country level
    if (!hierarchy[country]) {
      hierarchy[country] = { count: 0, children: {} };
    }
    hierarchy[country].count += 1;

    // Create year level
    if (!hierarchy[country].children[year]) {
      hierarchy[country].children[year] = {
        count: 0,
        products: []
      };
    }
    hierarchy[country].children[year].count += 1;

    // Add product details
    hierarchy[country].children[year].products.push({
      active_substance_current: product.active_substance_current,
      eu_pnumber: product.eu_pnumber,
      eu_brand_name_current: product.eu_brand_name_current,
      eu_aut_date: year
    });
  });

  return hierarchy;
};

const transformsubstanceData = (data) => {
  const hierarchy = {};

  data.forEach(product => {
    if (!product.substance_type || !product.eu_aut_date) return;

    const substanceType = product.substance_type;
    const {
      atc_code_nameL1,
      atc_code_nameL2,
      atc_code_nameL3,
      atc_code_nameL4,
      atc_code_nameL5,
      active_substance_current,
      eu_pnumber,
      eu_brand_name_current,
      eu_aut_date
    } = product;

    const authYear = eu_aut_date ? eu_aut_date.split('-')[0] : '';
    const productDetails = {
      active_substance_current,
      eu_pnumber,
      eu_brand_name_current,
      eu_aut_date: authYear
    };

    // Initialize substance type level
    if (!hierarchy[substanceType]) {
      hierarchy[substanceType] = { count: 0, children: {} };
    }
    hierarchy[substanceType].count += 1;

    // Initialize and populate ATC levels with "No source available" handling
    let currentLevel = hierarchy[substanceType].children;
    let currentLevelName = atc_code_nameL1;

    if (currentLevelName) {
      if (!currentLevel[currentLevelName]) {
        currentLevel[currentLevelName] = { count: 0, children: {}, products: [] };
      }
      currentLevel[currentLevelName].count += 1;
      if (currentLevelName === "No source available") {
        currentLevel[currentLevelName].products.push(productDetails);
        return;
      }

      currentLevel = currentLevel[currentLevelName].children;
      currentLevelName = atc_code_nameL2;

      if (currentLevelName) {
        if (!currentLevel[currentLevelName]) {
          currentLevel[currentLevelName] = { count: 0, children: {}, products: [] };
        }
        currentLevel[currentLevelName].count += 1;
        if (currentLevelName === "No source available") {
          currentLevel[currentLevelName].products.push(productDetails);
          return;
        }

        currentLevel = currentLevel[currentLevelName].children;
        currentLevelName = atc_code_nameL3;

        if (currentLevelName) {
          if (!currentLevel[currentLevelName]) {
            currentLevel[currentLevelName] = { count: 0, children: {}, products: [] };
          }
          currentLevel[currentLevelName].count += 1;
          if (currentLevelName === "No source available") {
            currentLevel[currentLevelName].products.push(productDetails);
            return;
          }

          currentLevel = currentLevel[currentLevelName].children;
          currentLevelName = atc_code_nameL4;

          if (currentLevelName) {
            if (!currentLevel[currentLevelName]) {
              currentLevel[currentLevelName] = { count: 0, children: {}, products: [] };
            }
            currentLevel[currentLevelName].count += 1;
            if (currentLevelName === "No source available") {
              currentLevel[currentLevelName].products.push(productDetails);
              return;
            }

            currentLevel = currentLevel[currentLevelName].children;
            currentLevelName = atc_code_nameL5;

            if (currentLevelName) {
              if (!currentLevel[currentLevelName]) {
                currentLevel[currentLevelName] = { count: 0, products: [] };
              }
              currentLevel[currentLevelName].count += 1;
              currentLevel[currentLevelName].products.push(productDetails);
            } else {
              currentLevel[currentLevelName].products.push(productDetails);
            }
          } else {
            currentLevel[currentLevelName].products.push(productDetails);
          }
        } else {
          currentLevel[currentLevelName].products.push(productDetails);
        }
      } else {
        currentLevel[currentLevelName].products.push(productDetails);
      }
    }
  });

  return hierarchy;
};

const transformATCData = (data) => {
  const categories = {};

  data.forEach(product => {
    const {
      atc_code_nameL1,
      atc_code_nameL2,
      atc_code_nameL3,
      atc_code_nameL4,
      atc_code_nameL5,
      active_substance_current,
      eu_pnumber,
      eu_brand_name_current,
      eu_aut_date
    } = product;

    const authYear = eu_aut_date ? eu_aut_date.split('-')[0] : '';
    const productDetails = {
      active_substance_current,
      eu_pnumber,
      eu_brand_name_current,
      eu_aut_date: authYear
    };

    // Initialize first level
    if (!categories[atc_code_nameL1]) {
      categories[atc_code_nameL1] = { count: 0, children: {}, products: [] };
    }
    categories[atc_code_nameL1].count += 1;

    // Initialize and populate subsequent levels
    if (atc_code_nameL2) {
      if (!categories[atc_code_nameL1].children[atc_code_nameL2]) {
        categories[atc_code_nameL1].children[atc_code_nameL2] = { count: 0, children: {}, products: [] };
      }
      categories[atc_code_nameL1].children[atc_code_nameL2].count += 1;

      if (atc_code_nameL3) {
        if (!categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3]) {
          categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3] = { count: 0, children: {}, products: [] };
        }
        categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3].count += 1;

        if (atc_code_nameL4) {
          if (!categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3].children[atc_code_nameL4]) {
            categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3].children[atc_code_nameL4] = { count: 0, children: {}, products: [] };
          }
          categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3].children[atc_code_nameL4].count += 1;

          if (atc_code_nameL5) {
            if (!categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3].children[atc_code_nameL4].children[atc_code_nameL5]) {
              categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3].children[atc_code_nameL4].children[atc_code_nameL5] = { count: 0, products: [] };
            }
            categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3].children[atc_code_nameL4].children[atc_code_nameL5].count += 1;
            categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3].children[atc_code_nameL4].children[atc_code_nameL5].products.push(productDetails);
          } else {
            categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3].children[atc_code_nameL4].products.push(productDetails);
          }
        } else {
          categories[atc_code_nameL1].children[atc_code_nameL2].children[atc_code_nameL3].products.push(productDetails);
        }
      } else {
        categories[atc_code_nameL1].children[atc_code_nameL2].products.push(productDetails);
      }
    } else {
      categories[atc_code_nameL1].products.push(productDetails);
    }
  });

  return categories;
};


const TreeNode = ({ name, data, level = 0, path = [], config }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = data.children && Object.keys(data.children).length > 0;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const indent = "\u00A0".repeat(level * 4);

  const renderNodeName = () => {
    if (config.nameFormatter) {
      return config.nameFormatter(name);
    }

    if (name.includes('-')) {
      const [firstPart, ...rest] = name.split('-');
      const secondPart = rest.join('-');
      return (
        <>
          <span style={{ fontWeight: 'bold' }}>{firstPart.trim()}</span>
          <span> - {secondPart.trim()}</span>
        </>
      );
    }
    return <span style={{ fontWeight: 'bold' }}>{name}</span>;
  };

  const sortMonths = (a, b) => {
    if (monthNames.includes(a) && monthNames.includes(b)) {
      return monthNames.indexOf(a) - monthNames.indexOf(b);
    }
    return a.localeCompare(b);
  };

  return (
    <div>
      <div onClick={toggleExpand} style={{ cursor: 'pointer', padding: '5px' }}>
        {indent}
        {hasChildren && <span>{isExpanded ? "▼" : "▶"}</span>}
        {renderNodeName()}
        <span style={{ marginLeft: '5px' }}>({data.count})</span>
      </div>

      {isExpanded && (
        <>
          {hasChildren && (
            <div style={{ paddingLeft: '20px' }}>
              {Object.entries(data.children)
                .sort(([aName, aData], [bName, bData]) => {
                  if (monthNames.includes(aName)) {
                    return sortMonths(aName, bName);
                  }
                  return config.sortFn ? config.sortFn(aName, bName) : aName.localeCompare(bName);
                })
                .map(([childName, childData]) => (
                  <TreeNode
                    key={childName}
                    name={childName}
                    data={childData}
                    level={level + 1}
                    path={[...path, name]}
                    config={config}
                  />
                ))}
            </div>
          )}

          {data.products && data.products.length > 0 && (
            <div style={{ marginLeft: `${(level + 1) * 20}px`, marginTop: '10px' }}>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {data.products.map((product, index) => (
                  <li key={index} style={{ marginBottom: '10px', borderBottom: '1px solid #eee', paddingBottom: '5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
                        {capitalizeFirstLetter(product.active_substance_current)}:
                      </span>
                      <Link
                        to={`${config.detailsRoute}/${DataToString(product.eu_pnumber)}`}
                        style={{ color: '#007bff', textDecoration: 'none' }}
                      >
                        {product.eu_brand_name_current} ({product.eu_aut_date}) 🔎
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};

function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const HierarchicalViewer = ({
  data,
  viewType = 'atc',
  title = 'Hierarchy Viewer',
  description = '',
  config = {}
}) => {
  const defaultConfig = {
    detailsRoute: '/details',
    sortFn: null,
    nameFormatter: null,
    filterFn: null
  };

  const mergedConfig = { ...defaultConfig, ...config };

  const transformedData = {
    'atc': () => transformATCData(data),
    'date': () => transformDateData(data),
    'country': () => transformCountryData(data),
    'substance': () => transformsubstanceData(data)
  }[viewType]();

  if (!transformedData) {
    return <div>Loading data...</div>;
  }

  let filteredData = transformedData;
  if (mergedConfig.filterFn) {
    filteredData = Object.fromEntries(
      Object.entries(transformedData).filter(mergedConfig.filterFn)
    );
  }

  return (
    <div>
      <h2>{title}</h2>
      {description && <p>{description}</p>}
      <hr className="med-top-separator" />
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        paddingLeft: '10px',
        alignItems: 'flex-start'
      }}>
        {Object.entries(filteredData)
          .sort(([aName], [bName]) =>
            mergedConfig.sortFn ? mergedConfig.sortFn(aName, bName) : aName.localeCompare(bName)
          )
          .map(([name, categoryData]) => (
            <div key={name} style={{
              border: '1px solid #ccc',
              padding: '10px',
              borderRadius: '5px',
              minWidth: 'auto'
            }}>
              <TreeNode
                name={name}
                data={categoryData}
                config={mergedConfig}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default HierarchicalViewer;