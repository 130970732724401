// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useRef, useEffect, useState } from 'react'
import Menu from './menuSecondary/Menu'
import Search from '../../../shared/search/Search'
import TableView from '../shared/TableView'
import updateData from '../utils/update'

import { useTableUtilsSecondary, defaultTableUtils } from '../../../shared/contexts/Secondary/TableUtilsContextSecondary'
import { useColumnSelectionSecondary } from '../../../shared/contexts/Secondary/ColumnSelectionContextSecondary'
import { useDataSecondary } from '../../../shared/contexts/Secondary/DataContextSecondary'
import { useScrapeDate } from '../../../shared/contexts/ScrapeDateContext'
import config from '../../../config/config.js'

import getUniqueCategories from '../../visualizations/single_visualization/utils/getUniqueCategories'
import ToggleButtons from '../../components/ToggleButtons.js'
import { graphemeAt } from 'voca'
import OpenFields from '../../components/OpenFields'
import ClearButtons from '../../../shared/search/ClearButton'

// Function based component that displays the search bar and table with all the datapoints that can be selected
function DataSelectSecondary() {

  const { tableUtils, setTableUtils } = useTableUtilsSecondary()

  // We need to keep a reference of the columns
  // Used in ranking the data when applying a search
  const { columnSelection } = useColumnSelectionSecondary()
  let columnsRef = useRef(columnSelection)
  let queryRef = useRef(tableUtils.search)

  // We update the columns if a new search is initialized
  if (tableUtils.search !== queryRef.current) {
    columnsRef.current = columnSelection
    queryRef.current = tableUtils.search
  }

  const scrapeDate = useScrapeDate(config.api.endpoints.secondary_update_date)

  // Update the data based on the search, filters and sorters
  // The search, filters and sorters are all located in the tableUtils context
  // Also rank the data based on the current selected columns
  const allData = useDataSecondary()
  const updatedData = updateData(allData, tableUtils, columnsRef.current)

  // Handler that is called after the filters and sorters in the menu are applied
  const menuUpdate = (filters, sorters) => {
    // Update the filters and sorters context with the ones applied in the menu
    setTableUtils({
      ...tableUtils,
      filters: filters,
      sorters: sorters,
    })
  }

  // Store the categories of each variable (which are used in the filter options)
  const [categories, setCategories] = useState(null)
  useEffect(() => {
    setCategories(getUniqueCategories(allData))
  }, [allData])

  // Get the number of rows in total and after filters are applied
  const totalRows = allData.length
  const filteredRows = updatedData.length

  return (
    <>
      <Search
        tour="step-data-search"
        update={(e) => setTableUtils({ ...tableUtils, search: e })}
        initial={tableUtils.search}
        dataType={"secondary"}
      />
      <div tour="step-data-select" className="med-content-container">

        {/* <ToggleButtons
          clickFunction1={ButtonFunctions.OpenHuman}
          clickFunction2={ButtonFunctions.OpenOrphan}
        /> */}

        <h1 className="med-header">{config.data.secondary_name}
          <span> - Database last updated on {scrapeDate}</span>
          <span>
            {filteredRows === 0 && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>No data to display, please check your search or filters.</span>
                </div>
              </div>
            )}
          </span>
        </h1>

        <Menu
          filters={tableUtils.filters}
          sorters={tableUtils.sorters}
          update={menuUpdate}
          categories={categories}
        />

        <hr className="med-top-separator" />
        <ClearButtons
          tableUtils={tableUtils}
          setTableUtils={setTableUtils}
          dataType="secondary"
          showWhenNoResults={true}
          filteredRows={filteredRows}
        />
        <TableView
          data={updatedData}
          sorters={tableUtils.sorters}
          setSorters={(e) => setTableUtils({ ...tableUtils, sorters: e })}
          select={true}
          text="No data to display, please check your search or filters."
          dataType={"secondary"}
        />
        {/* <hr className="med-top-separator" />
        <h3>
          *: The source document was uploaded before the introduction of this variable
        </h3> */}
      </div>
    </>
  )
}

export default DataSelectSecondary
