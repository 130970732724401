// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react';
import descriptions from '../../../config/descriptions'

// Function based component, represents a single detail entry, which
// preferably resides inside a detail group component
function Detail(props) {
  const [isHovered, setIsHovered] = React.useState(false);
  const [showExplanation, setShowExplanation] = React.useState(false);
  const [isExtended, setIsExtended] = React.useState(false); // State to track if scroll box is fully extended
  const explanationRef = React.useRef(null);
  let hoverTimeout;
  let leaveTimeout;

  const getAttributeDescription = () => {
    if (descriptions[props.attribute_name]) {
      return descriptions[props.attribute_name]
    }
    // default text if no description found
    return 'No description available';
  };

  const handleMouseEnter = () => {
    if (!explanationRef.current) {
      hoverTimeout = setTimeout(() => {
        setIsHovered(true);
        explanationRef.current = true;
      }, 1000);
    }
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    clearTimeout(leaveTimeout);

    leaveTimeout = setTimeout(() => {
      setIsHovered(false);

      // Keep the explanation box open if it was manually toggled
      if (!showExplanation) {
        explanationRef.current = false;
      }
    }, 300);
  };

  const handleToggleExplanation = () => {
    setShowExplanation(!showExplanation);
  };

  const handleExplanationClick = (e) => {
    // Stop the event propagation to prevent hiding the box when clicking inside it
    e.stopPropagation();

    // Toggle the explanation box visibility
    setShowExplanation(!showExplanation);

    // If the explanation box is manually toggled, consider it hovered
    explanationRef.current = !showExplanation;
  };

  const handleValueClick = () => {
    setIsExtended(!isExtended); // Toggle the state to extend or reduce the scroll box
  };

  // Cleanup timeouts on unmount
  React.useEffect(() => {
    return () => {
      clearTimeout(hoverTimeout);
      clearTimeout(leaveTimeout);
    };
  }, []);

  const getATCLink = (code) => {
    // Only create the link if the code matches expected ATC code patterns
    return `https://atcddd.fhi.no/atc_ddd_index/?code=${code}`;
  };

  const isATCkey = (key) => {
    const atcKeys = [
      'atc_code',
      'atc_code_nameL1',
      'atc_code_nameL2',
      'atc_code_nameL3',
      'atc_code_nameL4',
      'atc_code_nameL5'
    ];
    return atcKeys.includes(key);
  }

  const cleanATCCODE = (text) => {
    // Trim the input to avoid leading or trailing spaces
    text = text.trim();

    // Case where code is already valid
    if (isValidATCCode(text)) {
      return text;
    }

    // Split the string at the first dash ("-") to separate the ATC code and description
    const parts = text.split('-');

    // If there is no dash or the description part is missing, return false
    if (parts.length < 2 || parts[1].trim() === '') {
      return false;
    }

    // The ATC code (part before the dash)
    const atcCode = parts[0].trim();
    return atcCode
  }

  // Helper function to determine if the ATC code is valid
  const isValidATCCode = (atcCode) => {
    // Regex pattern to match ATC codes like A, A04, A04A, A04AA, A04AA05
    const atcCodePattern = /^[A-Z]([0-9]{2}[A-Z]*[0-9]*)?$/i;
    return atcCodePattern.test(atcCode);
  };
  return (
    <div className="med-info-detail">
      <span
        className="med-info-detail-name"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleToggleExplanation}
      >
        {props.name}{' '}
        <span
          className={`question-mark ${isHovered || showExplanation ? 'show-explanation' : ''}`}
          onClick={handleExplanationClick}
        >
          ?
        </span>
        {(isHovered || showExplanation) && (
          <div
            className={`explanation-box`}
            onClick={(e) => e.stopPropagation()} // Prevent hiding the box when clicking inside it
          >
            <div className="explanation-text">
              {getAttributeDescription()}
            </div>
          </div>
        )}
      </span>
      <span
        className={`med-info-detail-value ${isExtended ? 'extended' : ''}`} // Apply extended class if isExtended is true
        onClick={handleValueClick} // Toggle isExtended state when clicked
      >
        {isATCkey(props.attribute_name) && isValidATCCode(cleanATCCODE(props.value)) ? (
          <>
            <a
              href={getATCLink(cleanATCCODE(props.value.split('-')[0]))}
              target="_blank"
              rel="noopener noreferrer"
              title={`Link to WHO ATC Index for ${props.value.split(' -')[0]}`}
            >
              {props.value.split(' -')[0]}
            </a>
            {props.value.includes('-') ? ' - ' + props.value.split('-').slice(1).join('-').trim() : ''}
          </>
        ) : (
          props.value
        )}
      </span>
    </div>
  );
}

export default Detail;