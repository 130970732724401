// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useContext, useState, useEffect } from 'react'
import { useData } from './DataContext'
import config from '../../config/config'

// Create a new React context for the checked datapoints state
const CheckedContext = React.createContext()

// Function that returns the context such that the data can be used in other components
export function useCheckedState() {
  return useContext(CheckedContext)
}

// Provider component that provides the checked state in the application
export function CheckedProvider({ children }) {
  // The data is necessary to create a checked state based on the eu numbers
  const data = useData()

  // Initialize the state which is empty as long as the data is not retrieved yet
  const [checkedState, setCheckedState] = useState({})

  // Load checked state from localStorage when the component mounts
  useEffect(() => {
    const savedCheckedState = localStorage.getItem('checkedState')
    
    if (savedCheckedState) {
      setCheckedState(JSON.parse(savedCheckedState))  // Load from localStorage
    } else {
      // Initialize default checked state if nothing is saved
      const initialCheckedState = Object.assign(
        {},
        ...data.map((entry) => ({ [entry[config.data.primary_key]]: false }))
      )
      setCheckedState(initialCheckedState)
    }
  }, [data])

  // Save the checked state to localStorage whenever it changes
  useEffect(() => {
    if (Object.keys(checkedState).length > 0) {
      localStorage.setItem('checkedState', JSON.stringify(checkedState))
    }
  }, [checkedState])

  return (
    <CheckedContext.Provider value={{ checkedState, setCheckedState }}>
      {children}
    </CheckedContext.Provider>
  )
}
