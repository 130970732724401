import React, { useState } from 'react'
import TableView from '../shared/TableView'
import ExportMenu from './export_menu/ExportMenu'
import ClearAll from '../shared/components/ClearAll'
import ClearAllSecondary from '../shared/components/ClearAllSecondary'
import sortData from '../utils/sorting'
import config from '../../../config/config'

// Function based component that displays the selected datapoints
function SelectedData({ selectedData, dataType }) {
  // Local sort state of the selected table
  const [sorters, setSorters] = useState([{ selected: '', order: 'asc' }])
  // State to track whether the component is expanded or collapsed
  const [isExpanded, setIsExpanded] = useState(false)

  // Update the data based on the sorters
  const updatedData = sortData(selectedData, [...sorters])

  const dataType_name = dataType === "main" ? config.data.name : config.data.secondary_name

  // Toggle expanded/collapsed state
  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div
      tour="step-data-selected"
      className={`med-content-container selected-data-container ${isExpanded ? 'expanded-container' : ''}`}
    >
      <div>
        <div className="header-container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button className="med-primary-solid med-bx-button" onClick={toggleExpand}>
                    <i className={`bx ${isExpanded ? 'bx-chevron-up' : 'bx-chevron-down'}`}></i>
                    {isExpanded ? 'Hide selection' : 'Show Selection'}
                </button>

                <h1 className="med-header" style={{ marginLeft: '10px' }}>
                    Selected {dataType_name}
                    {updatedData.length === 0 && (
                        <span> - No {dataType_name} selected. Use the checkboxes below or load EMRD data.</span>
                    )}
                    {updatedData.length > 0 && (
                        <span> - {updatedData.length} in selection</span>
                    )}
                </h1>
            </div>

            {updatedData.length > 0 && (
                <div className="button-container">
                    <ExportMenu selectedData={updatedData} text="Export Selection" />

                    {dataType === "main" ? (
                        <ClearAll data={updatedData} />
                    ) : (
                        <ClearAllSecondary data={updatedData} />
                    )}

                    <button
                        className="med-bx-button med-data-button med-primary-solid"
                        onClick={() => (window.location.href = config.routes.main.visualizations)}
                    >
                        <i className="bx bx-bar-chart med-button-image"></i> Visualize Selection
                    </button>
                </div>
            )}
            {updatedData.length === 0 && (
                <div className="button-container">
                    <button
                        className="med-bx-button med-data-button med-primary-solid"
                        onClick={() => {
                            // Implement load selection logic here
                        }}
                    >
                        <i className="bx bx-folder-open med-button-image"></i> Load data
                    </button>
                </div>
            )}
        </div>
</div>
      {isExpanded && (
        <div className="selected-data-content">
          <hr className="med-top-separator" />

          <TableView
            data={updatedData}
            sorters={sorters}
            setSorters={setSorters}
            select={false}
            text="No data selected. Select data points from the table below or load a previously exported EMRD dataset."
            dataType={dataType}
            pageSize={5}
          />
        </div>
      )}
    </div>
  )
}

export default SelectedData