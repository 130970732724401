// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import './AboutPage.css'
import '../../shared/shared.css'
import uuLogo from '../../images/uu-logo.svg'
import mebLogo from '../../images/meb-logo.svg'
import Search from '../../shared/search/Search'
import { useNavigate } from 'react-router-dom'
import { useTableUtils } from '../../shared/contexts/TableUtilsContext'
import config from '../../config/config'

// Function based component rendering the info page
function AboutPage() {
  const navigate = useNavigate()
  const { tableUtils, setTableUtils } = useTableUtils()

  // Set the query in the utils context and navigate to the datapage
  const search = (query) => {
    setTableUtils({ ...tableUtils, search: query })
    navigate(config.routes.main.data)
  }
  return (
    // Infopage components, contains article containers
    <div className="med-info-content">
      <Search update={search}/>
      <section className="med-content-container">
        <h1>ABOUT</h1>
      </section>
      <section className="med-content-container">
        <h2>About the team</h2>
        <hr className="med-top-separator" />
        <img
            src={mebLogo}
            alt="Logo of the Medicines Evaluation Board"
            className="med-institution-logo"
          />
          <img
            src={uuLogo}
            alt="Logo of the University of Utrecht"
            className="med-institution-logo"
          />

          <p className="med-institution-info">
            The EMRD project is a joint initiative of Utrecht University (UU) and the Dutch Medicines Evaluation Board
            (MEB). The project was initiated and is currently led by Dr. Lourens Bloem (UU), Dr. Jarno Hoekman (UU), Dr.
            Marjon Pasmooij (MEB) and Stefan Verweij (MEB).
          </p>
      </section>

      <section className="med-content-container">
        <h1>Acknowledgements</h1>
        <hr className="med-top-separator" />
          <p>
            Students from the bachelor Information Sciences organized in project team MedCTRL and MediSee have greatly
            contributed to the development of the dashboard as part of their Software Project course at the Utrecht
            University. We are also grateful to several other organizations that have supported EMRD and contributed
            to bringing it alive.


          </p>
      </section>

      <section className="med-content-container">
        <h1>Funding</h1>
        <hr className="med-top-separator" />

          <p>
            Funding in support of the EMRD was received from the Dutch Cancer Society KWF [INSERT FUNDING ID] and the
            Dutch Growth Fund project 'Oncode Accelerator.'
          </p>
      </section>
    </div>
  )
}

export default AboutPage
