const API_SEARCH_URL = `${process.env.PUBLIC_URL}/api-docshark/search`;
const API_SEARCH_COUNT_URL = `${process.env.PUBLIC_URL}/api-docshark/search_count`;
const API_HIGHLIGHTS_URL = `${process.env.PUBLIC_URL}/api-docshark/highlights`;
const API_HEALTH_URL = `${process.env.PUBLIC_URL}/api-docshark/health`;

export const performSearch = async (searchQuery, selectedScope = [], selectedFileTypes = [], setLoadingStage) => {
  try {
    const response = await fetch(API_SEARCH_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: searchQuery,
        file_types: selectedFileTypes,
        eu_pnumbers: selectedScope,
        limit: 10000, // Or any other limit you want
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    setLoadingStage('Loading...'); // Update loading stage here
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API call failed:", error);
    throw error; // Rethrow the error to be handled by the component
  }
};

export const getSearchCount = async (searchQuery, selectedScope = [], selectedFileTypes = []) => {
  try {
    const response = await fetch(API_SEARCH_COUNT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: searchQuery,
        file_types: selectedFileTypes,
        eu_pnumbers: selectedScope,
        limit: 10000, // Or any other limit you want.
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API call failed:", error);
    throw error;
  }
};

export const getHighlights = async (filename, query, maxFragments = 5, fragmentSize = 100) => {
  try {
    const response = await fetch(API_HIGHLIGHTS_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        filename: filename,
        query: query,
        max_fragments: maxFragments,
        fragment_size: fragmentSize,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API call failed:", error);
    throw error;
  }
};

export const checkHealth = async () => {
  try {
    const response = await fetch(API_HEALTH_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.ok; // Return true if response is ok, false otherwise.

  } catch (error) {
    console.error("API call failed:", error);
    return false; // Return false if an error occurs.
  }
};