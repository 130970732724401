import React from 'react'
import Chart from 'react-apexcharts'
import * as Colors from './Colors'
import { useStructureMapping } from '../../../../shared/contexts/StructureContext'

// bar chart component
function BoxplotChart(props) {
  const dataKeyToValueMap = useStructureMapping()

  // initializing the state with data passed from the form
  let settings = {
    options: {
      chart: {
        id: String(props.id),
        type: 'boxPlot',
        toolbar: { show: false },
        fontFamily: 'Poppins, sans-serif',
        foreColor: 'var(--text-primary)',
        events: {
          dataPointSelection: (event, chartContext, config) => {
            let variables =
              props.series[config.seriesIndex].variables[config.dataPointIndex];
            props.onDataClick(variables);
          },
        },
      },
      colors: Colors.boxplot_colors,
      plotOptions: {
        bar: {
          horizontal: !props.options.horizontal,
          distributed: false,
        },
      },
      title: {
        text: props.name ? props.title : '',
        align: 'center',
        style: {
          fontWeight: '500',
          fontSize: '20px', // Increased font size for the title
          color: 'var(--text-primary)',
        },
      },
      xaxis: {
        labels: {
          hideOverLappingLabels: false,
          rotateAlways: true,
          trim: true,
        },
        title: {
          text: props.options.horizontal ? dataKeyToValueMap[props.options.yAxis] : dataKeyToValueMap[props.options.xAxis],
          style: {
            fontWeight: '300',
            fontSize: 'small',
            color: 'var(--text-primary)',
          },
        },
        // Removed `categories` property here
      },
      yaxis: {
        title: {
          text: props.options.horizontal ? dataKeyToValueMap[props.options.xAxis] : dataKeyToValueMap[props.options.yAxis],
          style: {
            fontWeight: '300',
            fontSize: 'small',
            color: 'var(--text-primary)',
          },
        },
      },
      dataLabels: {
        enabled: props.labels,
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          // Return only the median value
          return w.config.series[seriesIndex].data[dataPointIndex].y[2]; // y[2] is median in ApexCharts boxplot
        },
      },
      legend: {
        show: props.legend,
      },
      noData: {
        text: `You can select the categories to be displayed.`,
      },
      states: {
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
    },
    ...props.series,
  };

  // RENDERER:

  // renders the bar chart with the given options
  return (
    <div className="med-vis-chart">
      <Chart
        options={settings.options}
        series={settings.series || []}
        type= "boxPlot"
        height={700}
      />
    </div>
  )
}

export default BoxplotChart