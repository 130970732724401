import React, { useState, useRef, useEffect } from 'react';
import config from '../../config/config';

const DocSharkSearchGuide = ({ isOpen, onClose }) => {
  const overlayRef = useRef(null);
  const contentRef = useRef(null);

  // Handle clicking outside the popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the overlay ref exists and the click is outside the content
      if (
        overlayRef.current &&
        contentRef.current &&
        !contentRef.current.contains(event.target)
      ) {
        onClose();
      }
    };

    // Add event listener when popup is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);

      // Prevent background scrolling when popup is open
      document.body.style.overflow = 'hidden';
    }

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  // If not open, return null
  if (!isOpen) return null;

  return (
    <div
      ref={overlayRef}
      className="docshark-search-guide-overlay"
    >
      <div
        ref={contentRef}
        className="docshark-search-guide"
      >
        <div className="search-guide-header">
          <h2>DocShark🦈 Search Guide</h2>
          <button
            className="close-button"
            aria-label="Close"
            onClick={onClose}
          >
            ×
          </button>
        </div>

        <div class="search-guide-content">
          <p>
            Welcome to the DocShark Search Guide!
            This guide is your cheatsheet to effectively use DocShark's search features, helping to find the information you need.
            It provides examples and explanations for basic and advanced search techniques.
          </p>

          <div class="menu-options">
            <h2>Menu options</h2>
            <div class="search-section">

              <h3><strong>Choose Scope:</strong></h3>
              <ul>
                <li><strong>All EMRD Medicines:</strong> Search across all medicines within the European Medicines Regulatory Database (EMRD).</li>
                <li><strong>Current EMRD Selection:</strong> Limit your search to the medicines currently selected on the <i className="bx bx-data"></i>{' '}
                  <a href={config.routes.main.data} className="med-link">data page</a>. This allows for focused searches on specific sets of medicines.</li>
              </ul>
            </div>
            <div class="search-section">
              <h3><strong>Filter by Document Type:</strong></h3>
              <p><strong>Tip:</strong><em> By default, DocShark searches through all available document types. For more specific results, select one or more document types above.</em></p>
              <ul>
                <li><strong>EPAR Documents:</strong> Includes EMA marketing documents: Public Assessment Report, Scientific Discussion, and procedural steps taken during the initial authorisation process.</li>
                <li><strong>Decision:</strong> Search within the official marketing authorisation decisions related to the medicines.</li>
                <li><strong>Annex:</strong> Focus your search on marketing authorisation annexes, which often contain detailed supplementary information.</li>
              </ul>
            </div>
          </div>
          <h2>DocShark🦈 Cheatsheet</h2>
          <div class="note">
            <p><strong>Note:</strong> DocShark search is not case sensitive. This means searching for "ibuprofen" will also find "Ibuprofen" and "IBUPROFEN".</p>
          </div>

          <div class="search-section">
            <h3>Basic Search Features</h3>
            <div class="search-example">
              <strong>Single Word Search</strong>
              <code>ibuprofen</code>
              <div class="explanation">Finds documents containing "ibuprofen"</div>
            </div>
            <div class="search-example">
              <strong>Multi Word Search</strong>
              <code>ibuprofen gel</code>
              <div class="explanation">Finds documents containing both "ibuprofen" AND "gel"</div>
            </div>
            <div class="search-example">
              <strong>Exact Phrase Search</strong>
              <code>"CZP 200mg"</code>
              <div class="explanation">Finds the exact phrase "CZP 200mg"</div>
            </div>
          </div>

          <div class="search-section">
            <h3>Advanced Search Features</h3>
            <div class="search-example">
              <strong>Exclusion Search</strong>
              <code>adult NOT child</code>
              <div class="explanation">Finds documents containing "adult", without containing "child"</div>
            </div>
            <div class="search-example">
              <strong>Alternative Search</strong>
              <code>"ibuprofen gel" OR "pain relief"</code>
              <div class="explanation">Finds documents containing either "ibuprofen gel" OR "pain relief"</div>
            </div>
            <div class="search-example">
              <strong>Proximity Search</strong>
              <code>"term1 term2"~5</code>
              <div class="explanation">Finds documents where "term2" is within 5 words after "term1"</div>
            </div>
            <div class="search-example">
              <strong>Grouping with Parentheses</strong>
              <code>(headache OR migraine) AND treatment</code>
              <div class="explanation">Finds documents containing either "headache" or "migraine" along with "treatment"</div>
            </div>
          </div>

          <div class="search-section">
            <h3>Wildcard Search Features</h3>
            <div class="search-example">
              <strong>Multi-Character Wildcard (*)</strong>
              <code>gel*</code>
              <div class="explanation">Matches any number of characters (gel, gels, gelled, gelcaps, etc.)</div>
            </div>
            <div class="search-example">
              <strong>Single-Character Wildcard (?)</strong>
              <code>ibuprof?n</code>
              <div class="explanation">Matches exactly one character (ibuprofen, ibuprofin)</div>
            </div>
            <div class="search-example">
              <strong>Combined Wildcards</strong>
              <code>cort*id?</code>
              <div class="explanation">Finds terms like "corticoid," "corticoids,", "corticosteroids" or "cortasid."</div>
            </div>
          </div>

          <div class="search-section">
            <h3>Search Tips</h3>
            <div class="search-example">
              <strong>Combine Techniques</strong>
              <code>"adverse events" AND adult* NOT (pediatric OR child*)</code>
              <div class="explanation">Combines exact phrase, wildcards, and exclusions for more specific results</div>
            </div>
          </div>

          <div class="note">
            <p><strong>Tip:</strong> For the most comprehensive results, try different search techniques and combinations. Start with broader terms and then refine with advanced operators as needed.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocSharkSearchGuide