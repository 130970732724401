// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import { Routes, Route } from 'react-router-dom'
import HomePage from '../pages/home/HomePage'
import InfoPage from '../pages/info/InfoPage'
import AboutPage from '../pages/about/AboutPage'
import DataPage from '../pages/data/DataPage'
import SecondaryPage from '../pages/Secondary/SecondaryPage'
import AccountPage from '../pages/account/AccountPage'
import VisualizationPage from '../pages/visualizations/VisualizationPage'
import VisualizationPageSecondary from '../pages/visualizations/VisualizationPageSecondary'
import DetailedInfoPage from '../pages/detailed-info/DetailedInfoPage'
import DetailedInfoPageSecondary from '../pages/detailed-info/DetailedInfoPageSecondary'
import ErrorPage from '../pages/error/ErrorPage'
import config from './../config/config'

// Returns the routing information to be able to navigate to different pages
function Routing() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/info" element={<InfoPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path={config.routes.main.data} element={<DataPage />} />
      <Route path={config.routes.main.visualizations} element={<VisualizationPage />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path={`${config.routes.main.details}/:medID`} element={<DetailedInfoPage />} />
      
      {/* Secondary routes conditionally rendered */}
      {config.features.secondaryData && (
        <>
          <Route path={config.routes.secondary.data} element={<SecondaryPage />} />
          <Route path={config.routes.secondary.visualizations} element={<VisualizationPageSecondary />} />
          <Route path={`${config.routes.secondary.details}/:medID`} element={<DetailedInfoPageSecondary />} />
        </>
      )}
      
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  )
}

export default Routing
