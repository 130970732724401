// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { slashDateToStringDate } from '../data/shared/table/format'
import { v4 } from 'uuid'
import { useData } from '../../shared/contexts/DataContext'
import { useStructure } from '../../shared/contexts/StructureContext'
import { fetchWithToken } from '../../shared/api'

import './DetailedInfo.css'
import DetailGroup from './components/DetailGroup'
import Detail from './components/Detail'
import Procedure from './components/Procedure'
import CustomLink from './components/CustomLink'
import TimeLine from './components/TimeLine'
import ProcSelectModal from './components/ProcSelectModal'
import DownloadChangelog from '../components/DownloadChangelog'
import CollapsibleButton from '../components/CollapsibleButton'

import { DataToString } from '../components/GeneralFunctions'
import { StringToData } from '../components/GeneralFunctions'

import { useTableUtils } from '../../shared/contexts/TableUtilsContext'
import { useNavigate } from 'react-router-dom'
import Search from '../../shared/search/Search'

import Col from 'react-bootstrap/esm/Col'
import CreateChangelog from '../components/CreateChangelog'

// Function based component, which represents the top level detailed info page
// component, it collects and fetches all the correct data and then passes this data
// to the info page component and returns that component
export default function DetailedInfoPage() {
  const { medID } = useParams()
  const [procData, setProcData] = useState(null)

  const navigate = useNavigate()
  const { tableUtils, setTableUtils } = useTableUtils()

  // Set the query in the utils context and navigate to the datapage
  const search = (query) => {
    setTableUtils({ ...tableUtils, search: query })
    navigate('/medicines')
  }

  // All information of all medicines is retrieved and the correct entry
  // corresponding to the desired medicine is extracted from the array

  const allData = useData()

  let medData = allData.find(
    (element) => element.eu_pnumber.toString() === StringToData(medID).toString(),
  )

  //console.log(medID)
  //console.log(StringToData(medID))

  // // All of the procedure data related to the desired medicine is asynchronously
  // // retrieved from the server. The result is stored in a state.
  // useEffect(() => {
  //   async function fetchProcedureData(medID) {
  //     const response = await fetchWithToken(
  //       `${process.env.PUBLIC_URL}/api/procedure/` + medID + '/',
  //       {
  //         method: 'GET',
  //         headers: { 'Content-Type': 'application/json' },
  //       }
  //     )
  //     const data = await response.json()
  //     setProcData(data)
  //   }
  //   fetchProcedureData(medID)
  // }, [setProcData, medID])

  // // Retrieve a date from the backend which indicates when the last update
  // // to the procedures in the database was. i.e. the database, and thus the
  // // procedure data on this page is complete up to this date
  const lastUpdatedDate = undefined

  return (
    <div>
      <Search update={search} />
      <InfoPage
        medData={medData}
        lastUpdatedDate={lastUpdatedDate}
      // procData={procData}
      />
    </div>
  )
}

// Function based component, which represents the entire detailed information page
// it display the given medicine and procedure data
export function InfoPage({ medData, lastUpdatedDate, procData }) {
  const variableCategories = useStructure()

  // A filter which determines which prodcures to show, and which to omit from
  // the detailed info page
  // const [procFilter, setProcFilter] = useState([
  //   'Centralised - Authorisation',
  //   'Centralised - Transfer Marketing Authorisation Holder',
  //   'Centralised - Annual reassessment',
  //   'Centralised - Annual renewal',
  // ])

  // If no medicine data is provided, no meaning full can be displayed
  if (!medData) {
    return (
      <h1 className="med-info-unknown-medID">Unknown Medicine ID Number</h1>
    )
  }

  // If there is procedure data present, two containers should be added to the page
  // with only the relevant procedure entries (based on the display proc types above)
  // one container should display the procedures in text form, and the other should
  // visually display them as a timeline. If no procedure data is present, both
  // containers should not be displayed.
  // let procedureContrainer = null
  // let timeLineContainer = null

  // if (procData !== null) {
  //   const removeDuplicates = (arr) => {
  //     return arr.filter((item, index) => arr.indexOf(item) === index)
  //   }
  //   const availableProcTypes = removeDuplicates(
  //     procData.map((proc) => proc.proceduretype)
  //   )
  //   const procSelectModal = (
  //     <ProcSelectModal
  //       availableProcTypes={availableProcTypes}
  //       currentProcFilter={procFilter}
  //       setProcFilter={setProcFilter}
  //     />
  //   )

  //   const procedures = procData.filter((proc) =>
  //     procFilter.includes(proc.proceduretype)
  //   )

  //   const noProcs = procedures.length === 0
  //   const noProcMessage = (
  //     <p className="med-info-no-proc-message">
  //       There are no procedures to display, make sure that there are procedure
  //       types selected for display in the menu in the top right corner of the
  //       container.
  //     </p>
  //   )

  //   procedureContrainer = (
  //     <div className="med-content-container med-info-container">
  //       <h1>Procedure Details</h1>
  //       {procSelectModal}
  //       <hr className="med-top-separator" />
  //       {!noProcs
  //         ? procedures.map((proc) => {
  //             return <Procedure proc={proc} key={v4()} />
  //           })
  //         : noProcMessage}
  //     </div>
  //   )

  //   timeLineContainer = (
  //     <div className="med-content-container med-info-container">
  //       <h1>Medicine Timeline</h1>
  //       {procSelectModal}
  //       <hr className="med-top-separator" />
  //       {!noProcs ? (
  //         <TimeLine procedures={procedures} lastUpdatedDate={lastUpdatedDate} />
  //       ) : (
  //         noProcMessage
  //       )}
  //     </div>
  //   )
  // }

  const detailGroups = []

  for (let category in variableCategories) {
    const details = []

    for (let varIndex in variableCategories[category]) {
      const variable = variableCategories[category][varIndex];
      if (variable['data-format'] !== 'link') {
        let value = medData[variable['data-key']]
        if (variable['data-format'] === 'date') {
          //value = slashDateToStringDate(value)
        }

        value = value.toString();

        details.push({
          name: variable['data-value'],
          attribute_name: variable['data-key'],
          value: value,
          key: v4()
        });
      }
    }

    // Sort details array based on the name property
    details.sort((a, b) => a.name.localeCompare(b.name))

    if (details.length > 0) {
      detailGroups.push(
        <DetailGroup title={category} key={v4()}>
          {details.map(detail => (
            // Custom Detail for 'ema_number' with link to EMA
            detail.attribute_name === 'ema_number' && isValidURL(medData.ema_url) ? (
              <Detail
                name={detail.name}
                value={
                  <>
                    {detail.value} <a href={medData.ema_url} target="_blank" rel="noopener noreferrer">(Visit EMA Page)</a>
                  </>
                }
                attribute_name={detail.attribute_name}
                key={detail.key}
              />
            ) : (
              // Default Detail rendering
              <Detail
                name={detail.name}
                value={detail.value}
                attribute_name={detail.attribute_name}
                key={detail.key}
              />
            )
          ))}
        </DetailGroup>
      )
    }
  }

  // Returns the component which discribes the entire detailed information page
  // the page consists of three containers, each holds a specific category of
  // information pertaining to the current medicine. This first holds general
  // information, the next procedure related information, and the last holds some
  // links to external website which could be useful.
  return (
    <div>
      <div className="med-content-container">
        <h1>Overview - {medData.eu_brand_name_current}</h1>
        <div className="med-content-overview-text">
          Hier een mooie text mocht dat nodig zijn:
          <br />
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum viverra elit sit amet tempus laoreet. Proin iaculis dolor eget nulla venenatis, ac placerat arcu dictum. Suspendisse commodo tincidunt metus eu bibendum. Etiam augue nisl, porttitor et viverra nec, egestas id nisi. Vestibulum sit amet faucibus justo, eu luctus mi. Proin mollis placerat mattis. Fusce at euismod mi. Morbi rutrum consequat lacus nec fermentum.
          <br />
          <br />
          Suspendisse vel sagittis ligula. Morbi pharetra non justo eget aliquet. Ut turpis lacus, condimentum vitae lectus non, aliquet vulputate est. Vivamus vitae lectus sit amet lacus ullamcorper interdum. Sed mollis vehicula pellentesque. Aliquam fermentum consectetur eleifend. Fusce eget varius sem. Quisque scelerisque volutpat eleifend.
        </div>
        <hr className="med-top-separator" />

        <div className="med-flex-columns">{detailGroups}</div>
      </div>

      {/* <div className="med-content-container">
        <h1 className="med-header">Changelog</h1>

        <DownloadChangelog
          data={Record}
          fileName={medData.eu_pnumber}
          fileType=".json"
        />

        <DownloadChangelog
          data={Record}
          fileName={medData.eu_pnumber}
          fileType=".txt"
        />


        <hr className="med-top-separator" />
        
        <CreateChangelog
          json={Record}
        />
      </div> */}

      {/* {timeLineContainer}
      {procedureContrainer} */}

      {<div className="med-content-container">
        <h1>Additional Resources</h1>
        <hr className="med-top-separator" />


        {isValidURL(medData.ema_url) && (
          <CustomLink
            className="med-info-external-link"
            name={'EMA Website for ' + medData.eu_brand_name_current}
            dest={medData.ema_url}
          />
        )}
        {isValidURL(medData.ec_url) && (
          <CustomLink
            className="med-info-external-link"
            name={'EC Website for ' + medData.eu_brand_name_current}
            dest={medData.ec_url}
          />
        )}
        {isValidURL(medData.aut_url) && (
          <CustomLink
            className="med-info-external-link"
            name={'Authorisation Decision'}
            dest={medData.aut_url}
          />
        )}
        {isValidURL(medData.smpc_url) && (
          <CustomLink
            className="med-info-external-link"
            name={'Authorisation SMPC'}
            dest={medData.smpc_url}
          />
        )}
        {isValidURL(medData.epar_url) && (
          <CustomLink
            className="med-info-external-link"
            name={medData.epar_url.includes('procedural') ? 'Procedural Steps Taken before Authorisation' : 'Initial Authorisation EPAR'}
            dest={medData.epar_url}
          />
        )}
        {isValidURL(medData.sci_disc_url) && (
          <CustomLink
            className="med-info-external-link"
            name={'Authorisation Scientific Discussion'}
            dest={medData.sci_disc_url}
          />
        )}
      </div>}

    </div>
  )

  function isValidURL(string) {
    // A simple check for URL validation
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }
}
