// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useState } from 'react'
import './ToggleButtons.css'
import './Button.css'

const ToggleButtons = ({ clickFunction1, clickFunction2 }) => {

    const [buttonId, setButtonId] = useState(1);
    const buttonString = "med-primary-solid med-bx-button-special med-selector-button";

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <input
                className={buttonId === 1 ? "button active " + buttonString : "button " + buttonString}
                onClick={() => {
                    clickFunction1(true);
                    setButtonId(1);
                }}
                value="Medicinal Products"
                type="button"
            />
            <input
                className={buttonId === 2 ? "button active " + buttonString : "button " + buttonString}
                onClick={() => {
                    clickFunction2(false);
                    setButtonId(2);
                }}
                value="Orphan Designations"
                type="button"
            />
        </div>
    )
}

export default ToggleButtons