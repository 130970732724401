// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import { Link } from 'react-router-dom'
import { StringToData } from '../../../../components/GeneralFunctions.js'
import { DataToString } from '../../../../components/GeneralFunctions.js'
import config from '../../../../../config/config.js'

// Function based component that holds actions that are always on the right side of the table
function RightStickyActions({ entry, select, onChange, dataType }) {

  let identifier = ""
  let address = ""

  if (dataType === "main")
  {
    identifier = entry[config.data.primary_key]
    address = config.routes.main.details
  }
  else
  {
    identifier = entry[config.data.secondary_primary_key]
    address = config.routes.secondary.details
  }

  let parseIdentifier = DataToString(identifier)

  return (
    <td className="med-table-body-cell med-table-narrow-column med-column-right">
      {
        /* Only render the delete icon if the table displays all the datapoints (not only the selected) */
        // !select && (
        //   <i
        //     className="bx bx-trash med-table-icons med-primary-text"
        //     onClick={onChange.bind(null, identifier)}
        //     role={'button'}
        //     tabIndex={'0'}
        //     onKeyPress={(e) => {
        //       if (e.key === 'Enter') onChange(identifier)
        //     }}
        //   ></i>
        // )
      }
      <Link to={`${address}/${parseIdentifier}`}>
        <i
          className="bx bx-info-circle med-table-icons med-primary-text"
          id={'detailInfo' + parseIdentifier}
          testid={'detailInfo' + parseIdentifier}
          title="View more details" // Hover text
        />
      </Link>
    </td>
  )
}

export default RightStickyActions
