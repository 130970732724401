// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import './Table.css'
import Header from './components/Header'
import ColumnAmount from './components/ColumnAmount'
import Body from './components/Body'
import { useTableUtils, defaultTableUtils } from '../../../../shared/contexts/TableUtilsContext'

// Function based component which renders the table
function Table(
  {
    data,
    select,
    amountPerPage,
    currentPage,
    sorters,
    setSorters,
    human
  }) {
  let { tableUtils, setTableUtils } = useTableUtils()

  // REFACTOR duplicate of DataSelect.js
  const isDefaultFilter = (filter) => {
    return (
      filter.selected === "" &&
      filter.input.length === 1 &&
      filter.input[0].var === ""
    )
  }
  const filtersApplied = (tableUtils.filters &&
    tableUtils.filters.some(filter => !isDefaultFilter(filter)))

  // Use defaultTableUtils to reset the state
  const clear = () => {
    setTableUtils(defaultTableUtils)
  }



  if (human) {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            {filtersApplied && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span>Active filters found. Clear them?</span>
                <button
                  className="med-primary-solid med-bx-button med-clear-button"
                  onClick={clear}
                  style={{ marginLeft: '10px' }}
                >
                  Clear Filters
                </button>
              </div>
            )}
          </div>

          <ColumnAmount />
        </div>
        <table className={!select ? 'med-table med-table-select' : 'med-table'}>
          <Header
            data={data}
            select={select}
            sorters={sorters}
            setSorters={setSorters}
            human={human}
          />
          <Body
            data={data}
            select={select}
            amountPerPage={amountPerPage}
            currentPage={currentPage}
            human={human}
          />
        </table>
      </>
    )
  }
}

export default Table
