// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React, { useContext, useState, useEffect } from 'react'

// Create a new React context for the table utils data
// This is used to store the applied search, filters and sorters
const TableUtilsContextSecondary = React.createContext()

export const defaultTableUtils = {
  search: '',
  filters: [{ selected: '', input: [{ var: '', value: '' }] }],
  sorters: [],
  columnAmount: 5
}

// Function that returns the context such that the data can be used in other components
export function useTableUtilsSecondary(query = '') {
  return useContext(TableUtilsContextSecondary)
}

// Provider component that provides the table utils data in the application
export function TableUtilsProviderSecondary({ children }) {
  // Initialize the state with an empty query and one unspecified filter and sorter item
  const [tableUtils, setTableUtils] = useState(defaultTableUtils)

  // Load filters and sorters from localStorage when the component mounts (not the search field)
  useEffect(() => {
    const savedTableUtils = localStorage.getItem('tableUtilsSecondary')
    
    if (savedTableUtils) {
      const parsedTableUtils = JSON.parse(savedTableUtils)
      // Ensure search is always initialized to an empty string when loading from localStorage
      setTableUtils({
        ...parsedTableUtils,
        search: '', // Reset search to an empty string
      })
    }
  }, [])

  // Save only filters and sorters to localStorage whenever they change (ignore search field)
  useEffect(() => {
    if (tableUtils) {
      const { filters, sorters } = tableUtils
      localStorage.setItem('tableUtilsSecondary', JSON.stringify({ filters, sorters }))
    }
  }, [tableUtils.filters, tableUtils.sorters])  // Only save when filters or sorters change


  return (
    <TableUtilsContextSecondary.Provider value={{ tableUtils, setTableUtils }}>
      {children}
    </TableUtilsContextSecondary.Provider>
  )
}
