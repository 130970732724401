// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

export function DataToString(identifier) {

  let str = identifier
  str = str.replace(/\//g, '_');

  return str
}

export function StringToData(identifier) {

  let str = identifier
  str = str.replace(/_/g, '/');

  return str
}

export function CapitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function FormatData(value, def, type) {
  if (
    value === null ||
    value === undefined ||
    value === '' ||
    value === 'unknown' ||
    value === 'NA'
  ) {
    return def
  }

  switch (type) {
    case 'number':
      return value.toString() // parseInt(value)
    case 'bool':
      if (value === true)
        return "Yes";
      else if (value === false)
        return "No";
      else
        return CapitalizeFirstLetter(value.toString());
    case 'string':
      return value.toString()
    case 'link':
      return value.toString()
    case 'date':
      return value.toString()
    // let splitteddate = value.split('-')

    // if (splitteddate[1] == null)
    // {
    //   return splitteddate[0]
    // }
    // else
    // {
    //   return splitteddate[1] + "penis" + splitteddate[2] + '/' + splitteddate[0]
    // }

    case '[string]':
      return value.join(', ')
    default:
      return def
  }
}