// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'

// Function based component, represents detail group component which
// preferably holds individual detail components, which are included as childern
function DetailGroup(props) {
  return (
    <div className="med-info-detail-group">
      <h2 className="med-info-detail-group-title">{props.title}</h2>
      {props.children}
      {props.title === "Ingredients & substances" && (
        <div className="atc-explanation">
          <br />
          For more information on the ATC code system, check out:
          <ul>
            <li>
              <a 
                href="https://atcddd.fhi.no/atc_ddd_methodology/purpose_of_the_atc_ddd_system/" 
                target="_blank" 
                rel="noopener noreferrer"
                title={`Link to WHO ATC Index`}
              >
                Purpose of the ATC/DDD System
              </a>
            </li>
            <li>
              <a 
                href="https://atcddd.fhi.no/atc/structure_and_principles/" 
                target="_blank" 
                rel="noopener noreferrer"
                title={`Link to WHO ATC Index`}
              >
                Structure and Principles of the ATC System
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default DetailGroup
