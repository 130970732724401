import React from 'react'
import { v4 } from 'uuid'
import { useStructureExclude } from './contexts/StructureContext'

export default function VariableSelectNumeric({
    className,
    onChange,
    defaultValue,
    dataTestId,
    name,
    tour,
  }) {
    const variableCategories = useStructureExclude()
    const optGroups = []
  
    for (let category in variableCategories) {
      const options = []
  
      for (let varIndex in variableCategories[category]) {
        const variable = variableCategories[category][varIndex]
        
        // Only include variables with 'data-format' equal to 'numeric'
        if (variable['data-format'] === 'number') {
          options.push(variable)
        }
      }
  
      // Sort the options alphabetically based on the 'data-value' attribute
      options.sort((a, b) => a['data-value'].localeCompare(b['data-value']))
  
      if (options.length > 0) {
        optGroups.push(
          <optgroup label={category} key={v4()}>
            {options.map((variable) => (
              <option value={variable['data-key']} key={v4()}>
                {variable['data-value']}
              </option>
            ))}
          </optgroup>
        )
      }
    }
  
    return (
      <select
        className={className}
        onChange={onChange}
        value={defaultValue}
        data-testid={dataTestId}
        name={name}
        tour={tour}
      >
        <option value="" key={v4()} hidden>
          Choose a variable...
        </option>
        {optGroups}
      </select>
    )
  }
  