// This program has been developed by students from the bachelor Computer Science at
// Utrecht University within the Software Project course.
// © Copyright Utrecht University (Department of Information and Computing Sciences)

import React from 'react'
import { CheckedProvider } from './contexts/CheckedContext'
import { ColumnSelectionProvider } from './contexts/ColumnSelectionContext'
import { DataProvider } from './contexts/DataContext'
import { SelectedProvider } from './contexts/SelectedContext'
import { StructureProvider } from './contexts/StructureContext'
import { TableUtilsProvider } from './contexts/TableUtilsContext'
import { VisualsProvider } from './contexts/VisualsContext'
import { CheckedProviderSecondary } from './contexts/Secondary/CheckedContextSecondary'
import { ColumnSelectionProviderSecondary } from './contexts/Secondary/ColumnSelectionContextSecondary'
import { DataProviderSecondary } from './contexts/Secondary/DataContextSecondary'
import { SelectedProviderSecondary } from './contexts/Secondary/SelectedContextSecondary'
import { StructureProviderSecondary } from './contexts/Secondary/StructureContextSecondary'
import { TableUtilsProviderSecondary } from './contexts/Secondary/TableUtilsContextSecondary'
import { VisualsProviderSecondary } from './contexts/Secondary/VisualsContextSecondary'
import { ScrapeDateProvider } from './contexts/ScrapeDateContext'
import config from '../config/config'

// General provider component that provides all the stored data
// to all the other components of the application
function Provider({ mock, children }) {
  return (
    <StructureProvider mock={mock?.structure}>
      <DataProvider mock={mock?.data}>
        <CheckedProvider>
          <SelectedProvider>
            <VisualsProvider>
              <TableUtilsProvider>
                <ColumnSelectionProvider>
                  {/* Secondary providers only included if feature is enabled */}
                  {config.features.secondaryData ? (
                    <StructureProviderSecondary mock={mock?.structureSecondary}>
                      <DataProviderSecondary mock={mock?.dataSecondary}>
                        <CheckedProviderSecondary>
                          <SelectedProviderSecondary>
                            <VisualsProviderSecondary>
                              <TableUtilsProviderSecondary>
                                <ColumnSelectionProviderSecondary>
                                  <ScrapeDateProvider>
                                    {children}
                                  </ScrapeDateProvider>
                                </ColumnSelectionProviderSecondary>
                              </TableUtilsProviderSecondary>
                            </VisualsProviderSecondary>
                          </SelectedProviderSecondary>
                        </CheckedProviderSecondary>
                      </DataProviderSecondary>
                    </StructureProviderSecondary>
                  ) : (
                    <ScrapeDateProvider>
                      {children}
                    </ScrapeDateProvider>
                  )}
                </ColumnSelectionProvider>
              </TableUtilsProvider>
            </VisualsProvider>
          </SelectedProvider>
        </CheckedProvider>
      </DataProvider>
    </StructureProvider>
  )
}

export default Provider
