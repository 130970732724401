export default function convertDatesToYear(data) {
    return data.map(item => {
        // Convert eu_aut_date and chmp_opinion_date to year only if they are valid date strings
        const convertDate = (dateString) => {
            const date = new Date(dateString);
            return isNaN(date) ? dateString : date.getFullYear().toString();
        };
  
        return {
            ...item,
            eu_aut_date: convertDate(item.eu_aut_date),
            chmp_opinion_date: convertDate(item.chmp_opinion_date),
            eu_od_date: convertDate(item.eu_od_date)
        };
    });
  }